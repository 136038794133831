import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Stack, Pagination, Box, Button } from "@mui/material";
import _ from "lodash";
import moment from 'moment';
// import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import { CSVLink } from "react-csv";

const formatDate = function(column: any, alert: any) {
    const isoDate: string = _.get(alert, column.path);
    return moment(isoDate).format("DD/MM/YYYY HH:00");
}

const formatDuration = function(column: any, alert: any) {
    const duration = _.get(alert, column.path);
    return `${duration}H`;
}

const renderDetails = function(column: any, alert: any) {
    switch(alert.properties.type) {
        case "THUNDER":
            return `Grêle : ${alert.properties.hail ? "Oui" : "Non"}`;
        default:
            return null
    }
}

const columns = [{
    label: "Code Postal",
    path: "properties.postal_code",
}, {
    label: "Type",
    path: "properties.type",
}, {
    label: "Debut",
    path: "properties.expected_at",
    transform: formatDate
}, {
    label: "Fin",
    path: "properties.estimated_end",
    transform: formatDate
}, {
    label: "Durée",
    path: "properties.risk_duration",
    transform: formatDuration
}, {
    label: "Niveau",
    path: "properties.level",
}, {
    label: "Détails",
    transform: renderDetails
}];

const renderColumnValue = function(column: any, alert: any) {
    if (column.transform) {
        return column.transform(column, alert);
    }

    return _.get(alert, column.path);
}

const filterAlerts = function(alerts: any[], typeFilters: any) {
    if (!alerts || alerts.length === 0) { return []; }
    const selectedTypes = typeFilters
        .map(function(type: any) { return {label: type.type, selected: type.isShow}})
        .filter(function(type: any) { return type.selected })
        .map(function(type: any) { return type.label });
        return alerts.filter(function(alert: any) { return selectedTypes.includes(alert.properties.type) });
}

const selectAlertPage = function(alerts: any[], page: number, itemsPerPage: number) {
    if (!alerts || alerts.length === 0) { return []; }
    return alerts.slice((page - 1) * itemsPerPage, page * itemsPerPage);
}

const itemsPerPage = 20;

export default function AlertTable({ data = {features: []}, typeFilters }: {data: any, typeFilters: any}) {
    const filteredAlerts = useMemo(function() {
        return filterAlerts(data.features, typeFilters);
    }, [data, typeFilters]);
    const alertCount = filteredAlerts.length;

    const [alerts, setAlerts] = useState<any>([]);
    // const { t } = useTranslation();

    useEffect(function() {
        setAlerts(selectAlertPage(filteredAlerts, 1, itemsPerPage));
    }, [data, filteredAlerts]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setAlerts(selectAlertPage(filteredAlerts, value, itemsPerPage));
    };

    const dataToCsv = useMemo(function() {
        return filteredAlerts.map(function(alert: any) {
            return columns.reduce(function(acc: any, column: any) {
                acc[column.label] = renderColumnValue(column, alert);
                return acc;
            }, {});
        })
    }, [filteredAlerts]);

    return (
        <Box>
            <TableContainer sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 4, backgroundColor: "white" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map(function(column: any, cellIndex: number) {
                                    return (
                                        <TableCell key={`header-row-cell-${cellIndex}`}>{column.label}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        alerts && alerts.map(function(alert: any, lineIndex: number) {
                            return (
                                <TableRow key={`row-${lineIndex}`}>
                                    {
                                        columns.map(function(column: any, cellIndex: number) {
                                            return (
                                                <TableCell key={`line-${lineIndex}-cell-${cellIndex}`}>{renderColumnValue(column, alert)}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
                <Stack alignItems="center" paddingY={4}>
                    <Pagination
                        count={Math.ceil(alertCount / itemsPerPage)}
                        onChange={handlePageChange}
                    />
                </Stack>
            </TableContainer>
            <Box sx={{display: "flex", paddingX: 8}}>
                <p style={{flex: 1}}>
                    <span>{alertCount} Alertes</span>
                </p>
                <CSVLink filename="data.csv" data={dataToCsv} headers={columns.map(function(column: any) { return column.label; })}>
                    <Button variant="contained">Exporter</Button>
                </CSVLink>
            </Box>
        </Box>
    )
}