import { useEffect, useState } from "react";
import { /*useAppDispatch,*/ useAppSelector } from "../../app/hooks";
import SearchResultBlock from "../../components/Block";
// import {selectAddress} from "../address/addressSlice";
import { useSnackbar } from "notistack";
import { CatnatType, ResultBlockType } from "../../types";
import {
  /*fetchClimateRiskAction,*/ selectClimateRisk,
} from "../risk/climateRiskSlice";
import moment from "moment";
import Icon from "../../components/Icon";
import { modelIconCatnat } from "../../constants";
import "./Catnat.css";
import { useTranslation } from "react-i18next";
import ButtonConfirm from "../../components/ButtonConfirm/ButtonConfirm";
import DataGridComponent from "../../components/DataGridComponent/DataGridComponent";
import { GridColDef } from "@mui/x-data-grid";
// import { selectAutoComplete } from "../address/addressSlice";

export default function CatnatRiskListBlock({
  id,
  iconType,
  title,
}: ResultBlockType) {
  // const dispatch = useAppDispatch();
  // const address = useAppSelector(selectAddress);
  const climatRisk = useAppSelector(selectClimateRisk);
  const { enqueueSnackbar } = useSnackbar();
  const [catnatList, setCatnatList] = useState<CatnatType[]>([]);
  const [isShowFullList, setIsShowFullList] = useState<boolean>(false);
  const { t } = useTranslation();

  // disable this effect for now (and code dependencies), climate risk request is already dispatched by climate risk block, just wait for the result
  // useEffect(() => {
  //     if (!address?.value) {
  //         return;
  //     }
  //     dispatch(fetchClimateRiskAction());
  // }, [address?.value, dispatch]);

  useEffect(() => {
    if (climatRisk.error) {
      enqueueSnackbar("L'interrogation des risques climatiques a échoué", {
        variant: "error",
      });
    }

    if (climatRisk?.value?.risks?.catnats) {
      const data = [...climatRisk?.value?.risks?.catnats].map((catnat, index) => {
        return {
          id: index,
          ...catnat
        }
      });

      if (isShowFullList) {
        setCatnatList(data);
      } else {
        const listCatnatFinal: CatnatType[] = [];

        // The label of catnat is unique (there are no two catnats with the same label but different types)
        // The list of catnats original is sorted by date
        // So if this label does not exist in any catnat in @listCatnatFinal, add the catnat
        data.forEach((catnat: CatnatType) => {
          const hasMatchingLabel = listCatnatFinal.some(
            (obj: CatnatType) => obj.label_FR === catnat.label_FR
          );

          if (!hasMatchingLabel) {
            listCatnatFinal.push(catnat);
          }
        });
        setCatnatList(listCatnatFinal);
      }
    }
  }, [climatRisk, enqueueSnackbar, isShowFullList]);

  useEffect(() => {
    setIsShowFullList(false);
  }, [climatRisk]);

  function parseDate(
    startDate: Date | undefined,
    endDate: Date | undefined,
    year: Date | undefined
  ): string {
    const parseStartDate = !!startDate
      ? moment(startDate).format("DD/MM/YY")
      : null;
    const parseEndDate = !!endDate ? moment(endDate).format("DD/MM/YY") : null;

    if (parseStartDate === null && parseEndDate === null && !!year) {
      return `En ${year}`;
    }
    if (parseStartDate === null && parseEndDate === null) {
      return "";
    }
    if (parseStartDate !== null && parseEndDate === null) {
      return `Le ${parseStartDate}`;
    }
    return `Du ${parseStartDate} au ${parseEndDate}`;
  }

  const handleShowListCatnat = () => {
    setIsShowFullList(!isShowFullList);
  };

  const columns: GridColDef[] = [
    {
      field: "icon",
      headerName: "Type",
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return <Icon type={`${params.row.type[0]}_catnat`} />;
      },
      minWidth: 45,
    },
    {
      field: "type",
      headerName: "Libellé",
      flex: 5,
      minWidth: 80,
      sortable: false,
      cellClassName: "dataCellText",
      renderCell: (params: any) => (
        <span>{params.row.type.map((type: string) => modelIconCatnat[type]).join(" - ")}</span>
      ),
    },
    {
      field: "label_FR",
      headerName: "Perils",
      cellClassName: "dataCellText",
      flex: 5,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 4,
      sortable: false,
      cellClassName: "dataCellText",
      renderCell: (params: any) => (
        <span>
          {parseDate(
            params.row?.startDate,
            params.row?.endDate,
            params.row?.year
          )}
        </span>
      ),
    },
  ];
  return (
    <SearchResultBlock
      style={{
        padding: "20px 20px 20px 20px",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <p className="--textTitleTable">
        {isShowFullList ? t("showCompleteList") : t("showLatestListByCategory")}
      </p>

      <DataGridComponent
        rows={catnatList}
        cols={columns}
        // rowId="_id"
        textNorows={t("noCatnat")}
      />
      <ButtonConfirm
        title={isShowFullList ? t("hideListCatnat") : t("showListCatnat")}
        disabled={catnatList.length === 0}
        onClick={handleShowListCatnat}
        sx={{marginTop: 2}}
      />
    </SearchResultBlock>
  );
}
