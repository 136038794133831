import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import AuthService from "./authService";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchUserInfoAction, selectUserInfo } from "../userInfo/userInfoSlice";
import CssBaseline from "@mui/material/CssBaseline";

import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import Icon from "../../components/Icon";
import AuthInput from "./AuthInput";
import AuthButton from "./AuthButton";
import "./Auth.css";
import Copyright from "./Copyright";
import AuthAlert from "./AuthAlert";

const theme = createTheme();

export default function SignIn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionExpired = !!queryParams.get("sessionExpired") || false;
  const userInfo = useAppSelector(selectUserInfo);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setIsLoading(true);
      const accessToken = await AuthService.login(
        data.get("email")!.toString(),
        data.get("password")!.toString()
      );
      dispatch(fetchUserInfoAction(accessToken));
    } catch (error: any) {
      setIsLoading(false);
      if (error.response.status === 401) {
        setError("Identifiants incorrects");
      } else {
        setError("Une erreur s'est produite");
      }
    }
  };

  useEffect(function(){
    if (userInfo.value) {
      setIsLoading(false);
      navigate("/");
    }
  }, [userInfo, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            width: "550px",
            height: "650px",
            justifyContent: "space-between",
          }}
        >
          <Box margin="20px">
            <Icon type="logo_d4r" />
          </Box>
          <AuthAlert
            open={sessionExpired}
            message={t("sessionExpired")}
            type="error"
          />
          <Box flex={1}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <p className="AuthTextTitle">{t("identify")}</p>
              <AuthInput
                id="email"
                type="text"
                icon="mail"
                name="email"
                label={t("email")}
              />
              <AuthInput
                id="password"
                type="password"
                icon="lock"
                name="password"
                label={t("password")}
              />
              <div
                className="error-container"
                style={{ fontSize: "0.8em", color: "red" }}
              >
                {error ? <span>{error}</span> : <span>&nbsp;</span>}
              </div>
              <AuthButton type="submit" label={t("connect")} loading={isLoading} disabled={isLoading}/>
              <Box display="flex" justifyContent="center">
                <Link
                  to="/auth/forgot-password"
                  style={{ color: "#3C5278", fontSize: "16px" }}
                >
                  {t("forgotPassword")}
                </Link>
              </Box>
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
