import {useState, useRef} from "react";
import _ from "lodash";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import {createRoot} from "react-dom/client";
import { useTranslation } from "react-i18next";

import PopupInfoAlert from "../PopupInfoAlert";

import {MBXTOKEN, modelAlertLayer, modelIconAlert} from "../../../constants";
import {AlertItemCheckBoxType} from "../../../types";

const weatherTypes = modelIconAlert.map((item) => item.id);

export default function useMapboxHandler(listCheckBoxChanged?: any) {
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const { t } = useTranslation();

    const [listCheckBox, setListCheckBox] = useState<AlertItemCheckBoxType[]>(modelIconAlert);
    const [allChecked, setAllChecked] = useState<boolean>(true);
    const [alert, setAlert] = useState<any>([]);

    const initMapWeatherRisk = (mapRef: any) => {
        mapboxgl.accessToken = MBXTOKEN;
        const nav = new mapboxgl.NavigationControl();
        mapRef.current = new mapboxgl.Map({
            container: "mapBox",
            style: "mapbox://styles/mapbox/dark-v9",
            center: [4, 46.9],
            zoom: 5.4,
        });
        mapRef.current.addControl(nav, "bottom-left");
    };

    const onLoadMapWeather = (mapRef: any) => {
        mapRef.current.on("load", () => {
            let popup: mapboxgl.Popup | null = null;
            let marker: mapboxgl.Marker | null = null;
            if (mapRef.current) {
                addAlertSourceAndLayer(mapRef);

                onClickWeatherRiskElement(mapRef, popup, marker);

                setMouseHoverEventOnRiskElement(weatherTypes, mapRef);

                const geocoderContainer = document.getElementById("geocoder");

                if (geocoderContainer) {
                    const geocoder = new MapboxGeocoder({
                        accessToken: MBXTOKEN,
                        mapboxgl: mapboxgl,
                        countries: "FR, GP, MQ, YT",
                        marker: false,
                        types: "postcode",
                        placeholder: t("weatherScreen.geocoderPlaceHolder"),
                    });

                    if (geocoderContainer.firstChild) {
                        geocoderContainer.removeChild(geocoderContainer.firstChild);
                    }

                    geocoderContainer.appendChild(geocoder.onAdd(mapRef.current));
                }
                listCheckBox.forEach((item) => {
                    handleLayerAlert(item.id, item.isShow);
                });
            }
        });
    };

    const handleLayerAlert = (layerId: string, isChecked: boolean) => {
        if (isChecked) {
            mapRef.current?.setLayoutProperty(layerId, "visibility", "visible");
        } else {
            mapRef.current?.setLayoutProperty(layerId, "visibility", "none");
            setAllChecked(false);
        }
        const updatedListCheckBoxAlert = listCheckBox.map((item) => {
            if (item.id === layerId) {
                return {
                    ...item,
                    isShow: isChecked,
                };
            }
            return item;
        });
        setListCheckBox(updatedListCheckBoxAlert);
        listCheckBoxChanged && listCheckBoxChanged(updatedListCheckBoxAlert);
    };

    const onClickWeatherRiskElement = (mapRef: any, popup: mapboxgl.Popup | null, marker: mapboxgl.Marker | null) => {
        mapRef.current.on("click", (e: any) => {
            e.preventDefault();
            let clickedStateId: any = null;

            const relatedFeatures = _.filter(mapRef.current?.queryRenderedFeatures(e.point), (feature) => {
                return _.get(feature, "source") === "alerts";
            });
            if (clickedStateId !== null && clickedStateId !== undefined) {
                mapRef.current?.setFeatureState({source: "alerts", id: clickedStateId}, {click: false});
            }

            if (marker) {
                marker.remove();
            }
            if (popup) {
                popup.remove();
            }

            const container = document.createElement("div");
            createRoot(container).render(
                <PopupInfoAlert postal_code={relatedFeatures[0]?.properties?.postal_code} type={relatedFeatures[0]?.properties?.type} />
            );
            popup = new mapboxgl.Popup({closeButton: false, anchor: "center", offset: [0, -80]}).setDOMContent(container);
            if (relatedFeatures.length > 0) {
                const clickedFeatureId = relatedFeatures[0].id;
                mapRef.current?.setFeatureState({source: "alerts", id: clickedFeatureId}, {click: true});
                clickedStateId = clickedFeatureId;

                if (mapRef.current) {
                    marker = new mapboxgl.Marker().setLngLat(e.lngLat).setPopup(popup).addTo(mapRef.current);
                }
                if (marker) {
                    const markerDiv = marker.getElement();
                    markerDiv.addEventListener("mouseenter", () => marker && marker.togglePopup());
                    markerDiv.addEventListener("mouseleave", () => marker && marker.togglePopup());
                }
                setAlert(relatedFeatures.map((feature) => feature.properties));
            } else {
                setAlert([]);
            }
        });
    };

    const setMouseHoverEventOnRiskElement = (weatherTypes: any, mapRef: any) => {
        weatherTypes.forEach((weatherType: any) => {
            if (mapRef.current) {
                let hoveredStateId: any = null;
                mapRef.current.on("mousemove", weatherType, (e: any) => {
                    if (mapRef.current) {
                        mapRef.current.getCanvas().style.cursor = "pointer";
                        if (e.features && e.features.length > 0) {
                            if (hoveredStateId !== null && hoveredStateId !== undefined) {
                                mapRef.current.setFeatureState({source: "alerts", id: hoveredStateId}, {hover: false});
                            }
                            hoveredStateId = e.features[0].id;
                            mapRef.current.setFeatureState({source: "alerts", id: hoveredStateId}, {hover: true});
                        }
                    }
                });
                mapRef.current.on("mouseleave", weatherType, () => {
                    if (mapRef.current) {
                        mapRef.current.getCanvas().style.cursor = "";
                        if (hoveredStateId !== null) {
                            mapRef.current.setFeatureState({source: "alerts", id: hoveredStateId}, {hover: false});
                        }
                    }
                });
            }
        });
    };

    const addAlertSourceAndLayer = (mapRef: any) => {
        mapRef.current.addSource("alerts", {
            type: "geojson",
            generateId: true,
        });

        mapRef.current.addLayer(modelAlertLayer.pollutionLayer);
        mapRef.current.addLayer(modelAlertLayer.coldWaveLayer);
        mapRef.current.addLayer(modelAlertLayer.simplehotLayer);
        mapRef.current.addLayer(modelAlertLayer.heatWaveLayer);
        mapRef.current.addLayer(modelAlertLayer.snowLayer);
        mapRef.current.addLayer(modelAlertLayer.crueLayer);
        mapRef.current.addLayer(modelAlertLayer.windLayer);
        mapRef.current.addLayer(modelAlertLayer.stormLayer);
        mapRef.current.addLayer(modelAlertLayer.heavyRainLayer);
    };

    return {
        mapRef,
        listCheckBox,
        allChecked,
        alert,
        handleLayerAlert,
        initMapWeatherRisk,
        onLoadMapWeather,
        setListCheckBox,
        setAllChecked,
    };
}
