import React from "react";
import {useTheme} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

import {useAppSelector, useAppDispatch} from "../../app/hooks";
import {selectBuildingAgeModelData, fetchBuildingAgeModelDataAction} from "./aiDetectionSlice";
import {selectAddress} from "./addressSlice";
import {AppTheme} from "../../index";

import DotsLoader from "../../components/DotsLoader";
import Icon from "../../components/Icon";
import {buildingDataInfo} from "../../constants/search";
import { useTranslation } from 'react-i18next';

function renderBuildingDataItem(buildingDataItem: {label: string; value: any; unit: string; data: any}) {
    if (buildingDataItem.value === undefined || buildingDataItem.value === null) {
        return <span> - </span>;
    }
    let content: any, formatedValue: any;
    switch (buildingDataItem.unit) {
        case "sqrt_meter":
            formatedValue = new Intl.NumberFormat("fr-FR").format(buildingDataItem.value);
            content = (
                <span>
                    {`${formatedValue}`}m<sup>2</sup>
                </span>
            );
            break;
        case "meter":
            formatedValue = new Intl.NumberFormat("fr-FR").format(buildingDataItem.value);
            content = <span>{`${formatedValue}`}m</span>;
            break;
        case "bool":
            content = buildingDataItem.value ? <span>Oui</span> : <span>Non</span>;
            break;
        case "number":
            formatedValue = new Intl.NumberFormat("fr-FR").format(buildingDataItem.value);
            content = <span>{`${formatedValue}`}</span>;
            break;
        case "year":
            content = <span>{`${buildingDataItem.value}`}</span>;
            break;
        case "meter_or_boolean":
            content =
                buildingDataItem.data && buildingDataItem.data.features.length > 0 ? (
                    <span>
                        <span>Oui</span>
                        <span> ({`${buildingDataItem.data.features.length}`})</span>
                    </span>
                ) : (
                    <span>Non</span>
                );
            break;
        case "text":
            content = <span style={{textTransform: 'capitalize'}}>{`${buildingDataItem.value}`}</span>;
            break;
        default:
            <span></span>;
    }
    return content;
}

function AIBuildingAgeBuildingDataItem({index, data}: {index: number; data: any;}) {
    const theme = useTheme<AppTheme>();
    const buildingAge = useAppSelector(selectBuildingAgeModelData);
    // const address = useAppSelector(selectAddress)
    const dispatch = useAppDispatch();
    const handleEstimAgeButtonClick = () => {
        dispatch(fetchBuildingAgeModelDataAction());
    };

    return (
        <li key={`building-data-${index}`} className="BuildingDataItem" style={{backgroundColor: theme.backgroundColor.grey}}>
            <span className="BuildingDataLabel">{data.label}</span>
            {buildingAge.value !== undefined && buildingAge.status === "idle" && (
                <span className="BuildingDataValue">{buildingAge.value.building_age}</span>
            )}
            {buildingAge.value === undefined && buildingAge.status === "idle" && (
                <button className="BuildingDataValueButton" onClick={handleEstimAgeButtonClick}>
                    Estimer
                </button>
            )}
            {buildingAge.status === "failed" && (
                <button className="BuildingDataValueButton Fail" onClick={handleEstimAgeButtonClick}>
                    Réessayer
                </button>
            )}
            {buildingAge.status === "loading" && <DotsLoader />}
        </li>
    );
}

function BuildingDataItem({index, data}: {index: number; data: any}) {
    const theme = useTheme<AppTheme>();
    return (
        <>
            <li key={`building-data-${index}`} className="BuildingDataItem" style={{backgroundColor: theme.backgroundColor.grey}}>
                <span className="BuildingDataLabel" key={`BuildingDataLabel-${index}`}>
                    {data.label}
                </span>
                <span className="BuildingDataValue" key={`BuildingDataValue-${index}`}>
                    {renderBuildingDataItem(data)}
                </span>
                {buildingDataInfo[index] && data.label === buildingDataInfo[index].label &&(
                    <div className="TooltipInfo">
                        <Tooltip title={<p style={{fontSize: "1rem", textAlign: "center"}}>{buildingDataInfo[index].info}</p>}>
                            <IconRef />
                        </Tooltip>
                    </div>
                )}
            </li>
        </>
    );
}

const zoneTypeMap: any = {
    qpv: "Quartier prioraire de la ville",
    qpvAncienDegrade: "Quartier prioritaire de la ville - ancien ou dégradé",
    qva: "Quartier de veille active",
    zus: "Zone urbaine sensible",
    zfu: "Zone franche urbaine",
};

function BuildingDataSensibleNeighborhood({index, data}: {index: number; data: any}) {
    const theme = useTheme<AppTheme>();
    const zoneData = [];
    for (let zoneType in data.data) {
        const zones = data.data[zoneType].features;
        for (let zone of zones) {
            zoneData.push({...zone.properties, zoneType});
        }
    }
    return (
        <li key={`building-data-${index}`} className="BuildingDataZoneItem" style={{backgroundColor: theme.backgroundColor.grey}}>
            <p className="BuildingDataZoneSection" key={`BuildingDataZoneSection-${index}`}>
                <span className="BuildingDataLabel">{data.label}</span>
                <span className="BuildingDataValue">{renderBuildingDataItem(data)}</span>
            </p>
            {zoneData.map((item: any, id: number) => {
                return (
                    <p className="BuildingDataZone" key={`BuildingDataZone-${id}`}>
                        <span className="NeighborhoodId" key={`NeighborhoodId-${id}`}>
                            <span className="BuildingDataLabelMin">ID : </span>
                            <span className="BuildingDataValue">{item.CODQUART || item.CODE_QP}</span>
                        </span>
                        <span className="NeighborhoodName" key={`NeighborhoodName-${id}`} style={{fontSize: "0.95rem"}}>
                            <span className="BuildingDataLabelMin">Quartier : </span>
                            <span className="BuildingDataValue">{item.QUARTIER || item.NOM_QP}</span>
                        </span>
                        <span className="ZoneType" key={`ZoneType-${id}`}>
                            <span className="BuildingDataLabelMin">Type : </span>
                            <span className="BuildingDataValue">{zoneTypeMap[item.TYPE || item.zoneType]}</span>
                        </span>
                        <span className="City" key={`City-${id}`} style={{fontSize: "0.95rem"}}>
                            <span className="BuildingDataLabelMin">Communes : </span>
                            <span className="BuildingDataValue">{item.COMMUNES || item.COMMUNE_QP}</span>
                        </span>
                    </p>
                );
            })}
        </li>
    );
}

function BuildingHistoricInfos({index, data}: {index: number; data: any}) {
    const theme = useTheme<AppTheme>();

    const historicData = [];

    for (let building of data.data.features) {
        historicData.push({
            name: building.properties.appellation_courante,
            dist: building.properties.distance.calculated,
            historique: building.properties.historique,
            address: building.properties.adresse_1,
            protection: building.properties.precision_protection,
        });
    }

    return (
        <li key={`building-data-${index}`} className="BuildingDataZoneItem" style={{backgroundColor: theme.backgroundColor.grey}}>
            <p className="BuildingDataZoneSection" key={`BuildingDataHistoricSection-${index}`}>
                <span className="BuildingDataLabel">{data.label}</span>
                <span className="BuildingDataValue">{renderBuildingDataItem(data)}</span>
            </p>
            <div className="HistoricInfoWrapper">
                {historicData.map((item, id) => {
                    return (
                        <div className="HistoricInfoItem" key={`BuildingDataHistoricItem-${id}`}>
                            <span className="HistoricInfoItemName">
                                {item.name} ({Math.floor(parseInt(item.dist))} m - {item.address})
                            </span>
                            <span style={{paddingTop: "8px", paddingBottom: "8px"}}>Précision protection : {item.protection}</span>
                            <span style={{fontSize: ".9rem"}}>Historique : {item.historique}</span>
                        </div>
                    );
                })}
            </div>
        </li>
    );
}

function UrbanInfos ({index, data}: {index: number, data: any}) {
    const theme = useTheme<AppTheme>();
    const { t } = useTranslation();

    return (
        <li key={`building-data-${index}`} className="BuildingDataZoneItem" style={{backgroundColor: theme.backgroundColor.grey}}>
            <p className="BuildingDataZoneSection">
                <span className="BuildingDataLabel">{data.label}</span>
            </p>
            <span className="UrbanInfoItem">
                {t(`${data.value}`)}
            </span>
        </li>
    );
}

function BuildingDataItemPlaceHolder({index}: {index: number}) {
    return (
        <li key={`building-data-place-holder-${index}`} className="BuildingDataItem PlaceHolder">
            <span className="BuildingDataLabel"></span>
            <span className="BuildingDataValue"></span>
        </li>
    );
}

function renderBuildingData(address: any, buildingDataList: any) {
    if (address.value && address.value[buildingDataList] && address.value[buildingDataList].length > 0) {
        return address.value[buildingDataList].map((data: any, index: number) => {
                    if (data.label === "Année de construction" && (data.value === undefined || data.value === null)) {
                        return (
                            <AIBuildingAgeBuildingDataItem
                                key={`building-data-item-${index}`}
                                index={index}
                                data={data}
                            />
                        );
                    } else if (data.label === "Bâtiment dans une zone surveillée") {
                        return <BuildingDataSensibleNeighborhood key={`building-data-item-${index}`} index={index} data={data} />;
                    } else if (data.label === "Bâtiment historique à moins de 500m") {
                        return <BuildingHistoricInfos key={`building-data-item-${index}`} index={index} data={data} />;
                    } else if (data.label === "Utilisation du terrain") {
                        return <UrbanInfos key={`building-data-item-${index}`} index={index} data={data}/>
                    }
                    return <BuildingDataItem key={`building-data-item-${index}`} index={index} data={data} />;
                })

    } else {
        return (
            <>
                <BuildingDataItemPlaceHolder index={0} />
                <BuildingDataItemPlaceHolder index={1} />
                <BuildingDataItemPlaceHolder index={2} />
            </>
        );
    }
}

function BuildingData({buildingDataList, ...other}: any) {
    const address = useAppSelector(selectAddress);

    return (
        <ul className="Side BuildingData" {...other}>
            {renderBuildingData(address, buildingDataList)}
        </ul>
    );
}

export default BuildingData;

const IconRef = React.forwardRef(function IconRef(props: any, ref: any) {
    return (
        <div {...props} ref={ref}>
            <Icon type="info" />
        </div>
    );
});
