import { useEffect, useState } from "react";
import SearchResultBlock from "../../components/Block";
import Map from "../map/Map";
import BuildingData from "../address/BuildingData";
import { useAppSelector } from "../../app/hooks";
import "./Search.css";
import { ResultBlockType } from "../../types";
import { getElevationFromGoogle } from "../address/data4homeAPI";
import StraightenIcon from '@mui/icons-material/Straighten';

import { useAppDispatch } from "../../app/hooks";
import { fetchWaterWaysAction, selectAddress, selectWaterWays } from "../address/addressSlice";
import {AppTheme} from "../../index";
import {useTheme} from "@material-ui/core";

export default function MapSearchResultBlock({
  iconType,
  title,
  id,
}: ResultBlockType) {
  const address = useAppSelector(selectAddress);
  const dispatch = useAppDispatch();

  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.866667,
    lng: 2.333333,
  });
  const waterWays = useAppSelector(selectWaterWays);
  const theme = useTheme<AppTheme>();
  // const [elevation, setElevation] = useState<any>(null);


  useEffect(() => {
    if (
      !address ||
      !address.value ||
      !address.value.latitude ||
      !address.value.longitude
    ) {
      return;
    }
    setCenter({ lat: address.value.latitude, lng: address.value.longitude });
    setZoom(15);
  }, [address]);

  // useEffect(function(){
  //   if (address.value) {
  //     dispatch(fetchWaterWaysAction(address.value.placeId, 5000));
  //   }
  // }, [dispatch, address]);

  // useEffect(function(){
  //   if (address.value) {
  //     (async function() {
  //       const elevation = await getElevationFromGoogle(address.value.latitude, address.value.longitude);
  //       setElevation(elevation);
  //     })();
  //   }
  // }, [dispatch, address]);

  return (
    <SearchResultBlock
      style={{ display: "flex", flexDirection: "row" }}
      className="MapResultBlock"
      iconType={iconType}
      id={id}
      title={title}
    >
      <div style={{ flex: 2, paddingRight: "10px" }}>
        <Map
          mapId="SearchMap"
          center={center}
          zoom={zoom}
          style={{ height: "600px", margin: "10px 0" }}
          data4HomeInfo={
            address && address.value
              ? {
                  buildings: address.value.buildings,
                  parcels: address.value.parcels,
                  empriseBatieParis: address.value.empriseBatieParis,
                }
              : undefined
          }
          waterWays={waterWays.value}
        />
        {/* <ul className="Side BuildingData">
          {waterWays.value &&
            <li className="BuildingDataItem" style={{backgroundColor: theme.backgroundColor.grey}}>
              <span className="BuildingDataLabel">Cours d'eau le plus proche dans un rayon de 1km</span>
              <span className="BuildingDataValue">
                <span style={{textTransform: "capitalize"}}>{waterWays.value.features[0].properties.name} </span>
                <span>(altitude: {waterWays.value.features[0].properties.altitudeAtEnd}m)</span>
              </span>
            </li>}
        </ul> */}
        <BuildingData buildingDataList="buildingData2" />
      </div>
      <BuildingData style={{ flex: 1 }} buildingDataList="buildingData1" />
    </SearchResultBlock>
  );
}
