import { Box, Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

export interface IAuthButtonProps {
  label: string;
  type: "submit" | "button" | "reset";
  loading?: boolean;
  disabled?: boolean
}

export default function AuthButton({ label, type, loading, disabled = false }: IAuthButtonProps) {
  return (
    <Box width="320px" sx={{ marginBottom: "20px", marginTop: "40px" }}>
      <Button
        type={type}
        fullWidth
        variant="outlined"
        sx={{
          borderColor: "#3C5278",
          color: "#3C5278",
          borderRadius: "20px",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "18px",
          height: 44
        }}
        disabled={disabled}
      >
        {loading ? <CircularProgress size={25}/> : label}
      </Button>
    </Box>
  );
}
