import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import HelpIcon from '@mui/icons-material/Help';
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import AppBar from "./AppBar";
import { Trans, useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState, ChangeEvent, Fragment, useEffect, useMemo, useRef } from 'react';
import { useSnackbar } from "notistack";
import { uploadAddressfile, bulkAddressUsage } from "../address/data4homeAPI"
import CSVParser from 'papaparse';
import { filesize } from "filesize";
import { selectUserInfo } from "../userInfo/userInfoSlice";
import { useAppSelector } from "../../app/hooks";

// import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from "@mui/material/Link";

// TODO: update download address csv file ui

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function ChipContent({ params, onchange }: any) {
  const { t } = useTranslation();
  const handleDistanceChange = (value: string) => {
    onchange({
      distance: value,
      unit: params.unit,
    });
  }

  const handleUnitChange = (value: string) => {
    onchange({
      distance: params.distance,
      unit: value,
    });
  }

  //TODO: input field validation

  return (
    <Fragment>
      <span>{params.title}</span>
      {
        params.unit
        ? (
          <Fragment>
            <input
              type="number"
              style={{
                border: "1px solid #CCCCCC",
                borderRadius: 4,
                paddingLeft: 8,
                width: 50
              }}
              placeholder="distance en mètre"
              value={params.distance}
              onChange={(e) => handleDistanceChange(e.target.value)}
            />
            <select value={params.unit} onChange={(e) => handleUnitChange(e.target.value)}>
              <option value="m">{t("m")}</option>
              <option value="km">{t("km")}</option>
            </select>
          </Fragment>
        )
        : null
      }
    </Fragment>
  )
}

export default function BulkAddressPage() {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [columnsSelection, setColumnsSelection] = useState<any>({available: [], selected: []});
  const [headerColumnsSelection, setHeaderColumnsSelection] = useState<any>({available: [], selected: []});
  const [fileContent, setFileContent] = useState<any>(null);
  const [hasHeader, setHasHeader] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [usageRequestLoading, setUsageRequestLoading] = useState<boolean>(false);
  const [usage, setUsage] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const userInfo = useAppSelector(selectUserInfo);
  const hasAddresssBulkFeature = userInfo.value && userInfo.value.groups && userInfo.value.groups.includes("/features/address_bulk");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const reportDataParameters = useMemo(function() {
    return [
      {
        title: 'surface du batîment au sol (m2)',
        id: 'geoshapeComputedArea',
        selected: true
      },
      {
        title: 'nombre d\'étages estimé',
        id: 'estimatedFloorCount',
        selected: true
      },
      {
        title: 'surface globale estimée (m2)',
        id: 'livingAreaEstimation',
        selected: true
      },
      {
        title: 'année de contruction',
        id: 'constructionYear',
        selected: true
      },
      {
        title: 'altitude (en metre au dessus du niveau de la mer)',
        id: 'altitude',
        selected: true
      },
      {
        id: 'waterWayName',
        title: 'Cours d\eau le plus proche dans les ',
        selected: true,
        distance: 5,
        unit: "km",
      },
      {
        id: 'waterWayDistance',
        title: 'Distance à vol d\'oiseau avec le cours d\'eau dans les ',
        selected: true,
        distance: 5,
        unit: "km",
      },
      {
        id: 'waterWayAltitude',
        title: 'Altitude normale (hors crue) du cours d\'eau le plus proche',
        selected: true
      },
      {
        id: 'waterWayDifference',
        title: 'Différence entre la hauteur à l\'adresse et le niveau du cours d\'eau le plus proche',
        selected: true
      },
      {
        title: 'zone surveillée',
        id: 'qpv',
        selected: true
      },
      {
        title: 'manifestation',
        id: 'manifestation',
        selected: true
      },
      {
        title: 'nombre de monuments historiques dans les ',
        id: 'historicMonuments',
        selected: true,
        distance: 500,
        unit: "m",
      },
      {
        title: 'nombre de site seveso dans les ',
        id: 'seveso',
        selected: true,
        distance: 20,
        unit: "km",
      },
      {
        title: 'risque de crue',
        id: 'flood',
        selected: true
      },
      {
        title: 'risque de sécheresse',
        id: 'drought',
        selected: true
      },
      {
        title: 'risque d\'incendies',
        id: 'fire',
        selected: true
      },
      {
        title: 'risque de grêle',
        id: 'hail',
        selected: true
      },
      {
        title: 'risque de fortes pluie',
        id: 'heavyRain',
        selected: true
      },
      {
        title: 'risque de vents violents',
        id: 'wind',
        selected: true
      },
      {
        title: 'risque de submersion',
        id: 'submersion',
        selected: true
      },
      {
        title: 'risque d\' érosion côtière',
        id: 'coastalErosion',
        selected: true
      },
      {
        title: 'présence de catnat pour chaque risque',
        id: 'catnat',
        selected: true
      },
      {
        title: 'présence de PPRn pour chaque risque',
        id: 'pprn',
        selected: true
      },
      {
        title: 'arrêtés de péril',
        id: 'perils',
        selected: true
      },
    ]}, []
  );

  const getUsage = async function() {
    setUsageRequestLoading(true);
    const usage = await bulkAddressUsage();
    setUsage(usage);
    setUsageRequestLoading(false);
  }

  useEffect(function() {
    const selectedColumns = reportDataParameters.slice();
    const availableColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = selectedColumns.map(function(selectedColumn) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });
    setColumnsSelection({
      selected: selectedColumns,
      available: availableColumns
    });

    getUsage();

  }, [reportDataParameters])

  const handleEventFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.files) {
        const file = event.target.files[0];
        // TODO: validate type
        setFile(file);
        const reader = new FileReader();
        reader.onload = (evt) => {
          const content = evt.target?.result as string;
          if (!content) {
            enqueueSnackbar(t('addressBulk.failedToReadAddressFileContent'));
            return;
          }
          setFileContent(content);
        };
        reader.readAsText(file);
      }
  };

  // TODO: validate file structure and try to geocode address (limit to 100 addresses)
  // TODO: test data.gouv geocoding api

  const handleEventFileSubmit = (): void => {
      // TODO: validate form parameters

      if (!file) {
          alert('Veuillez sélectionner un fichier à télécharger.');
          return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('columns', columnsSelection.selected.map(function(column: any) {
        return column.id
      }));
      formData.append('hasHeader', JSON.stringify(hasHeader));
      formData.append('address_columns', headerColumnsSelection.selected.map(function(column: any) {
        return column.id
      }));
      const distanceColumns = columnsSelection.selected.filter(function(column: any) {
        return column.distance !== undefined;
      });

      for (let column of distanceColumns) {
        formData.append(`${column.id}-distance`, `${column.distance}-${column.unit}`);
      }

      setRequestLoading(true);
      uploadAddressfile(formData)
      .then(data => {

        // Créer un lien pour le téléchargement
        const urlBlob = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = `report_${file.name}`; // Remplacez par le nom du fichier que vous voulez
        document.body.appendChild(a);
        a.click();

        // Nettoyer
        a.remove();
        window.URL.revokeObjectURL(urlBlob);

        enqueueSnackbar(
            "Le fichier a été traité avec succès",
            { variant: "success" }
        );

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setFile(null);
        setFileContent(null);
        setActiveStep(0);
        getUsage();
      })
      .catch((error) => {
          let message = "Le traitement du fichier a échoué"
          if (error.response) {
            const reader = new FileReader();
            reader.onload = (evt) => {
              try {
                const content = JSON.parse(evt.target?.result as string);
                message = content.error;
              } catch (exception) {
                // swallow exception as we already have a default message
              }
              enqueueSnackbar(
                  message,
                  { variant: "error" }
              );
            };
            reader.readAsText(error.response.data);
          }
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  /* report file column selection */

  const handleAdd = (data: any) => () => {
    const newSelectedColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = columnsSelection.selected.map(function(selectedColumn: any) { return selectedColumn.id; });
      return selectedColumnIds.includes(item.id) || data.id === item.id;
    });
    const newAvailableColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = newSelectedColumns.map(function(selectedColumn) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });

    setColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    });
  }

  const handleDelete = (data: any) => () => {
    const newSelectedColumns = columnsSelection.selected.filter(function(item: any) {
      return item.id !== data.id;
    });
    const newAvailableColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = newSelectedColumns.map(function(selectedColumn: any) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });

    setColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    })
  }

  const handleDistanceChange = (data: any) => (value: any) => {
    for (const item of reportDataParameters) {
      if (item.id === data.id) {
        item.distance = value.distance;
        item.unit = value.unit;
      }
    }
    const selectedColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = columnsSelection.selected.map(function(selectedColumn: any) { return selectedColumn.id; });
      return selectedColumnIds.includes(item.id)
    });
    const availableColumns = reportDataParameters.filter(function(item) {
      const selectedColumnIds = columnsSelection.selected.map(function(selectedColumn: any) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });
    setColumnsSelection({
      selected: selectedColumns,
      available: availableColumns
    });
  }

  const handleSelectAll = () => {
    const newSelectedColumns = reportDataParameters.filter(function(item) {
      return true;
    });
    const newAvailableColumns = reportDataParameters.filter(function(item) {
      return false;
    });

    setColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    });
  }

  const handleDeselectAll = () => {
    const newSelectedColumns = reportDataParameters.filter(function(item) {
      return false;
    });
    const newAvailableColumns = reportDataParameters.filter(function(item) {
      return true;
    });

    setColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    });
  }

  /* end report file column selection */

  const csvContent = useMemo<any[]|undefined>(function() {
    if (!fileContent) { return []; }

    const { data: csvContent } = CSVParser.parse<string[]>(fileContent, { header: false, skipEmptyLines: true });
    return csvContent;
  }, [fileContent]);

  /* header selection */

  const headers = useMemo(function() {
    if (!csvContent || csvContent.length === 0) {
      return [];
    }

    // generate column name sequentially until 703 cols, then break
    const aChar = 65;
    if (!hasHeader) {
      const generatedHeader = [];
      for (let i = 0; i < csvContent[0].length; i++) {
        const charList = [];
        const test = i % 26;
        const test2 = Math.floor(i / 26);
        if (test2 > 0) {
          charList.push(aChar + (test2 - 1));
        }
        charList.push(aChar + test);
        generatedHeader.push({
          title: String.fromCharCode(...charList),
          id: i
        })
      }
      return generatedHeader;
    }

    return csvContent[0].map(function(header: string, index: number) {
      return {
        title: header,
        id: index
      }
    });
  }, [hasHeader, csvContent]);

  useEffect(function() {
    if (csvContent && csvContent.length) {
      setHeaderColumnsSelection({
        selected: [],
        available: headers
      })
    }
  }, [csvContent, headers]);

  const handleHeaderAdd = (data: any) => () => {
    const newSelectedColumns = headers.filter(function(item: any) {
      const selectedColumnIds = headerColumnsSelection.selected.map(function(selectedColumn: any) { return selectedColumn.id; });
      return selectedColumnIds.includes(item.id) || data.id === item.id;
    });
    const newAvailableColumns = headers.filter(function(item: any) {
      const selectedColumnIds = newSelectedColumns.map(function(selectedColumn: any) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });

    setHeaderColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    });
  }

  const handleHeaderDelete = (data: any) => () => {
    const newSelectedColumns = headerColumnsSelection.selected.filter(function(item: any) {
      return item.id !== data.id;
    });
    const newAvailableColumns = headers.filter(function(item: any) {
      const selectedColumnIds = newSelectedColumns.map(function(selectedColumn: any) { return selectedColumn.id; });
      return !selectedColumnIds.includes(item.id)
    });

    setHeaderColumnsSelection({
      selected: newSelectedColumns,
      available: newAvailableColumns
    })
  }

  /* end header selection */

  const inputCSVFileIsValidationErrors = useMemo(function() {
    const errors: any[] = [];
    if (!csvContent || !csvContent.length) {
      errors.push({
        isValid: false,
        reason: 'addressBulk.inputFileValidation.isEmpty',
      });
      return errors;
    }

    // if (!csvContent[0] || csvContent[0][0] !== 'adresse') {
    //   errors.push({
    //     isValid: false,
    //     reason: 'addressBulk.inputFileValidation.theFirstColumnMustBeAddress',
    //   })
    // }

    // if (!csvContent[0] || csvContent[0].length !== 1) {
    //   errors.push({
    //     isValid: false,
    //     reason: 'addressBulk.inputFileValidation.mustContainOnlyOneColumn',
    //   })
    // }

    if (headerColumnsSelection.selected.length === 0) {
      errors.push({
        isValid: false,
        reason: 'addressBulk.inputFileValidation.columnsFormingAddressMustNotBeEmpty',
      })
    }

    if (usage && (csvContent.length - 1 > (usage.limit - (usage.total || 0)))) {
      errors.push({
        isValid: false,
        reason: 'addressBulk.inputFileValidation.rowCountIsGreaterThanUsage',
      })
      return errors;
    }

    return errors;
  }, [csvContent, headerColumnsSelection, usage]);

  const renderCSVFile = function(csvContent: any, headers: any, addAddressColumns: boolean = true) {
    // const rows = fileContent.split(lineSeparator);
    // const csvContent = rows.map(function(line: string) {
    //   const regex = new RegExp(`("?[^${fieldSeparator}]+"?)`, "g")
    //   const match = line.match(regex);
    //   return match;
    // });

    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead>
              <TableRow key="csv-header">
                {addAddressColumns ?
                  <TableCell key={`csv-address-line-header`}>
                    {t('addressBulk.addressColumnHeader')}
                  </TableCell>
                  : null
                }
                {
                  headers.map(function(cell: any, cellIndex: number) {
                    return (
                      <TableCell key={`csv-header-cell-${cellIndex}`}>{cell.title}</TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                csvContent && csvContent.map(function(columns: string[], lineIndex: number) {
                  if (hasHeader && lineIndex === 0) { return null; }
                  return (
                    <TableRow key={`csv-row-${lineIndex}`}>
                      {addAddressColumns ?
                        <TableCell key={`csv-address-line-${lineIndex}`}>
                          {
                            headerColumnsSelection.selected.map(function(header: any) {
                              return columns[header.id];
                            }).join(", ")
                          }
                        </TableCell>
                        : null
                      }
                      {
                        columns && columns.map(function(cell: string, cellIndex: number) {
                          return (
                            <TableCell key={`csv-line-${lineIndex}-cell-${cellIndex}`}>{cell}</TableCell>
                          )
                        })
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  const getUsageColor = function(usage: any) {
    if (!usage) {
      return "gray";
    }

    const { total, limit } = usage;
    if (total === undefined || total < (limit / 2)) {
      return "green";
    } else if (total < (limit * 0.75)) {
      return "orange";
    } else {
      return "red";
    }
  }

  const renderUsage = function() {
    if (usageRequestLoading) {
      return (
        <CircularProgress size="15px"/>
      );
    } else {
      return (<span style={{color: getUsageColor(usage)}}>{usage ? `${usage.total || 0}/${usage.limit}` : `-/-`}</span>);
    }
  }

  // TODO: title translation

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ backgroundColor: "white", minHeight: "100%" }}>
      <CssBaseline />
      <AppBar
        title={t("applicationMenu.menuItem.addressBulk")}
        position="fixed"
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      />
      {!hasAddresssBulkFeature && (
        <Box
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          <Trans i18nKey="accessDeniedLabel">You don't have access to this feature, go back to <Link href="/">&nbsp;Home</Link></Trans>
        </Box>
      )}
      { hasAddresssBulkFeature &&
        <Fragment>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              <p>1/ {t('addressBulk.help.fileContainsHeader')}</p>
              <p>2/ {t('addressBulk.help.selectHeaderColumnsThatFormsTheAddress')}</p>
              <p>3/ {t('addressBulk.help.example')}</p>
              <Box sx={{ marginY: 2 }}>
                {renderCSVFile([
                  ["130 rue lourmel", "75015", "Paris", "123465"],
                  ["Place de la République" , "Paris", "France", "123465"],
                  ["144 Rue de Vermand", "02100",  "Saint-Quentin", "123465"],
                  ["28 Rue Montaigne", "43687", "Strasbourg", "123465"]
                ], [{title: "voie"}, {title: "code postal"}, {title: "ville"}, {title: "données personnalisées"}], false)}
              </Box>
              <p>{t('addressBulk.help.inThisExample')}</p>
            </Box>
          </Modal>
          <Box>
            <Box
              sx={{
                paddingLeft: 3,
                paddingRight: 3,
                paddingTop: 10,
                display: "flex",
                alignItems: "center",
              }}>
              <Typography sx={{flex: 1}}>
                <span>{t('addressBulk.currentUsageLabel')} : </span>
                {renderUsage()}
              </Typography>
              <Button onClick={handleOpen}><HelpIcon/></Button>
            </Box>
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 3,
                paddingRight: 3,
              }}
            >
              <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                <Step key="file">
                  <StepButton onClick={handleStep(0)}>
                    <Typography>{t("addressBulk.uploadAddressFileLabel")}</Typography>
                  </StepButton>
                  <StepContent>
                    <Typography
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                      }}
                    >
                      {t("addressBulk.fileSelectionDescription")}
                    </Typography>
                    <Box
                      sx={{
                      }}
                    >
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        sx={{
                          backgroundColor: "#304770",
                        }}
                      >
                        {t('addressBulk.selectAddressFileLabel')}
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleEventFileChange}
                          multiple={false}
                          ref={fileInputRef}
                        />
                      </Button>
                      <Stack
                        sx={{
                          marginTop: 2,
                          marginBottom: 2,
                        }}
                      >
                        <span>
                          <span>{t('addressBulk.fileNameLabel')}: </span>
                          <span>{file && file.name}</span>
                        </span>
                        <span>
                          <span>{t('addressBulk.fileSize')}: </span>
                          <span>{file && filesize(file.size)}</span>
                        </span>
                      </Stack>
                      {/* <Box>
                        <Button type="submit">{t('send')}</Button>
                      </Box> */}
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("continue")}
                      </Button>
                      {/* <Button
                        disabled={0 === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button> */}
                    </Box>
                  </StepContent>
                </Step>

                <Step key="preview">
                  <StepButton onClick={handleStep(1)}>
                    <Typography>
                      <span>{t("addressBulk.previewLabel")} </span>
                      {file && inputCSVFileIsValidationErrors.length ? <span>({`${inputCSVFileIsValidationErrors.length} ${t('error(s)')}`})</span> : null}
                    </Typography>
                  </StepButton>
                  <StepContent>
                    <Box>
                      {t('addressBulk.hasHeaderLabel')} :
                      <Checkbox checked={hasHeader} onChange={(event) => { setHasHeader(event.target.checked) }}/>
                    </Box>
                    <Box
                      sx={{
                        display: "flex"
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          padding: 1
                        }}
                      >
                        <p>{t('addressBulk.selectedHeaderColumnsLabel')} :</p>
                        <Paper
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            flexGrow: 0,
                          }}
                          component="ul"
                        >
                          {headerColumnsSelection.available.map((header: any, index: number) => {
                                return (
                                  <ListItem key={`headerchip-${index}`}>
                                    <Chip
                                      label={header.title}
                                      onClick={handleHeaderAdd(header)}
                                      component="span"
                                    />
                                  </ListItem>
                                );
                          })}
                        </Paper>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                          padding: 1
                        }}
                      >
                        <p>{t('addressBulk.availableHeaderColumnsLabel')} :</p>
                        { headerColumnsSelection.selected.length ? <Paper
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            flexGrow: 0,
                          }}
                          component="ul"
                        >
                          {headerColumnsSelection.selected.map((header: any, index: number) => {
                                return (
                                  <ListItem key={`headerchip-${index}`}>
                                    <Chip
                                      label={<ChipContent params={header}/>}
                                      onDelete={handleHeaderDelete(header)}
                                    />
                                  </ListItem>
                                );
                          })}
                        </Paper> : null}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        p: 3
                      }}
                    >
                      {/* {t('addressBulk.fieldSeparatorLabel')} :
                      <select value={fieldSeparator} onChange={(event) => { setFieldSeparator(event.target.value); }}>
                        <option value=",">, ({t('addressBulk.comma')})</option>
                        <option value=";">; ({t('addressBulk.semicolon')})</option>
                      </select> */}
                      {/* {t('addressBulk.lineSeparatorLabel')} :
                      <select value={lineSeparator} onChange={(event) => { setLineSeparator(event.target.value); }}>
                        <option value={"\r\n"}>{t('addressBulk.windows')}</option>
                        <option value={"\n"}>{t('addressBulk.mac')}</option>
                        <option value={"\r"}>{t('addressBulk.linux')}</option>
                      </select> */}
                      {renderCSVFile(csvContent, headers)}
                    </Box>
                    <Box
                      sx={{
                        p: 3,
                        color: "red",
                      }}
                    >
                      {inputCSVFileIsValidationErrors?.map((error) => (
                        <p key={`error-${error.reason.split('.').shift()}`}>{t(error.reason)}</p>
                      ))}
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={inputCSVFileIsValidationErrors?.length !== 0}
                      >
                        {t("continue")}
                      </Button>
                      <Button
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t('back')}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>

                <Step key="generate">
                  <StepButton onClick={handleStep(2)}>
                    <Typography>{t("addressBulk.reportGenerationLabel")}</Typography>
                  </StepButton>
                  <StepContent>
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: 3,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        p: 0.5,
                        m: 1,
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{flex: 1}}>{t('addressBulk.availableColumnsLabel')}:</span>
                        <Button onClick={handleSelectAll}>{t('addressBulk.selectAll')}</Button>
                      </p>
                      {
                        columnsSelection.available.length
                        ? <Paper
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            flexGrow: 0,
                          }}
                          component="ul"
                        >
                          {columnsSelection.available.map((data: any) => {
                            return (
                              <ListItem key={data.id}>
                                <Chip
                                  label={`${data.title}${data.unit ? `${data.distance}${data.unit}` : ''}`}
                                  onClick={handleAdd(data)}
                                  component="span"
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                        : null
                      }
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        p: 0.5,
                        m: 1,
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{flex: 1}}>{t('addressBulk.selectedColumnsLabel')}:</span>
                        <Button onClick={handleDeselectAll}>{t('addressBulk.deselectAll')}</Button>
                      </p>
                      {
                        columnsSelection.selected.length
                        ? <Paper
                          sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            flexGrow: 0,
                          }}
                          component="ul"
                        >
                          {columnsSelection.selected.map((data: any) => {
                            return (
                              <ListItem key={data.id}>
                                <Chip
                                  label={<ChipContent params={data} onchange={handleDistanceChange(data)} />}
                                  onDelete={handleDelete(data)}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                        : null
                      }
                    </Box>
                  </Box>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        onClick={handleEventFileSubmit}
                        sx={{ mt: 1, mr: 1, minWidth: 200 }}
                        disabled={inputCSVFileIsValidationErrors?.length !== 0 || requestLoading}
                      >
                        {requestLoading ? <CircularProgress size={24} sx={{ color: "white" }}/> : t('addressBulk.generateReportLabel')}
                      </Button>
                      <Button
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t('back')}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
              {/* {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )} */}
            </Box>
          </Box>
        </Fragment>

      }

      {/* <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingLeft: 3,
          paddingRight: 3,
          paddingTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DataGridComponent
          rows={reportDataParameters}
          cols={columns}
          textNorows={t("noPeril")}
        />
      </Box> */}
      {/* <Box
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ButtonConfirm
          onClick={handleEventFileSubmit}
          title={t('addressBulk.generateReportLabel')}
          loading={requestLoading}
          size="250px"
        />
      </Box> */}
    </Box>
  )
}