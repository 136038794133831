import React, { useEffect, useState, useMemo } from "react";
import { scaleLevels } from "../../constants";
// import { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import { WmsMapType } from '@googlemaps/ogc';
import { getElevation, getElevationFromGoogle } from "../address/data4homeAPI";
// import * as VectorTile from '@northpoint-labs/vector-tiles-google-maps'
import './Map.css';
export interface MapProps extends google.maps.MapOptions {
  mapId: string,
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  data4HomeInfo?: any;
  selectedRisk?: string;
  selectedRcp?: string;
  zoneRiskInfo?: any;
  initialCenter?: google.maps.LatLngLiteral;
  grayscale?: boolean,
  waterWays?: any
}

// Fonction pour calculer l'angle en degrés entre deux points
function getAngle(p1: any, p2: any) {
  const dLng = (p2.lng - p1.lng) * Math.cos(p1.lat * Math.PI / 180); // Correction pour la longitude
  const dLat = p2.lat - p1.lat;
  const angleRad = Math.atan2(dLat, dLng);
  return angleRad * (180 / Math.PI); // Conversion en degrés
}

// Fonction pour obtenir le point médian
function getMidpoint(p1: any, p2: any) {
  return {
      lat: (p1.lat + p2.lat) / 2,
      lng: (p1.lng + p2.lng) / 2
  };
}

/**
 * Creates a control that recenters the map on Chicago.
 */
function createCenterControl() {
  const controlButton = document.createElement('button');
  controlButton.id = "measure-button"

  // Set CSS for the control.


  // controlButton.textContent = 'Mesurer';

  controlButton.innerHTML = '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="StraightenIcon"><path d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2m0 10H3V8h2v4h2V8h2v4h2V8h2v4h2V8h2v4h2V8h2z"></path></svg>'

  controlButton.title = 'Click to recenter the map';
  controlButton.type = 'button';

  return controlButton;
}

/**
 * Creates a control that recenters the map on Chicago.
 */
function createInfosControl() {
  const infoContainer = document.createElement('div');
  infoContainer.id = "measure-container";
  const infoLine1 = document.createElement('p');
  infoLine1.className = "measure-info-line";
  const infoLine2 = document.createElement('p');
  infoLine2.className = "measure-info-line";
  const closeButton = document.createElement('button');
  closeButton.id = "measure-container-close-button";
  infoContainer.appendChild(infoLine1);
  infoContainer.appendChild(infoLine2);
  infoContainer.appendChild(closeButton);

  closeButton.innerHTML = "x";
  closeButton.style.position = "absolute";
  closeButton.style.top = "0";
  closeButton.style.right = "0";
  closeButton.style.lineHeight = "15px";

  return infoContainer;
}

const Map: React.FC<MapProps> = ({
  mapId,
  initialCenter,
  onClick,
  onIdle,
  children,
  style,
  data4HomeInfo,
  selectedRisk,
  selectedRcp,
  zoneRiskInfo,
  grayscale = false,
  waterWays,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [isMeasureMode, setMeasureMode] = useState(false);
  const [measureMarker, setMeasureMarker] = useState<any>(null);
  const [line, setLine] = useState<any>(null);
  const [measureButton/*, setMeasureButton*/] = useState<any>(createCenterControl());
  // const [centerControlDiv, setCenterControlDiv] = useState<any>(null);
  const [infosControl/*, setInfosControl*/] = useState<any>(createInfosControl());

  // init map
  useEffect(() => {
    if (ref.current && !map) {
      const stylez = [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { saturation: -100 }
          ]
        }
      ];
      const map = new window.google.maps.Map(ref.current, {
        center: options.center,
        mapId, zoom: options.zoom,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'grayscale']
      }})

      // Create the DIV to hold the control.
      const centerControlDiv = document.createElement('div');
      // Append the control to the DIV.
      centerControlDiv.appendChild(measureButton);
      centerControlDiv.style.paddingRight = "10px";
      // setCenterControlDiv(centerControlDiv);
      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);

      // Create the DIV to hold the control.
      const centerControlDiv2 = document.createElement('div');
      // Append the control to the DIV.
      centerControlDiv2.appendChild(infosControl);
      //  setCenterControlDiv(centerControlDiv);
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv2);

      const mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });
      map.mapTypes.set('grayscale', mapType);
      if (grayscale) {
        map.setMapTypeId('grayscale');
      }

      setMap(map);

      if (!(window as any).mapInstance) {
        (window as any).mapInstance = {}
      }
      (window as any).mapInstance[mapId] = map
    }
  }, [ref, map, grayscale, mapId, options]);

  useEffect(function() {
    if (!map || !measureButton) { return; }

    const handler = () => setMeasureMode(!isMeasureMode);
    measureButton.addEventListener('click', handler);
    measureButton.className = !isMeasureMode ? "" : "active";

    return () => {
      measureButton.removeEventListener('click', handler);
    }

  }, [map, measureButton, isMeasureMode]);

  useEffect(function() {
    if (!map) { return; }

    if (isMeasureMode) {
      map.setOptions({
        draggableCursor: "crosshair",
        // draggingCursor: "pointer"
      });

      const eventHandler = map.addListener("click", handleClick);

      return () => {
        eventHandler.remove();
        map.setOptions({
          draggableCursor: undefined,
          // draggingCursor: "pointer"
        });
        line && line.setMap(null);
        measureMarker && measureMarker.setMap(null);
      }
    } else {
      map.setOptions({
        draggableCursor: undefined,
        // draggingCursor: "pointer"
      });
      line && line.setMap(null);
      measureMarker && measureMarker.setMap(null);
      infosControl.children[0].innerHTML = "";
      infosControl.children[1].innerHTML = "";
      infosControl.style.display = "none";
    }

  }, [isMeasureMode, measureMarker]);

  const handleClick = useMemo(function() {
    return function(pt: any) {
      if (!map) { return; }

      measureMarker && measureMarker.setMap(null);
      line && line.setMap(null);

      const marker = new google.maps.Marker({
        position: pt.latLng,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5,
          labelOrigin: new google.maps.Point(0, 5),
        },
        map,
      });
      setMeasureMarker(marker);
    }

  }, [map, measureMarker]);

  useEffect(function() {
    if (!map || !measureMarker) { return; }

    const line = new google.maps.Polyline({
      path: [options.center, measureMarker.position],
      geodesic: true,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    line.setMap(map);
    setLine(line);

    const { lat, lng } = {lat: measureMarker.position.lat(), lng: measureMarker.position.lng()};
    const distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(options.center!, { lat, lng });

    infosControl.children[0].innerHTML = `distance: ${Math.floor(distanceMeters)}m`;
    infosControl.children[1].innerHTML = `altitude: -`;
    (async function() {
      const { altitude } = await getElevation(lat, lng);
      infosControl.children[1].innerHTML = `altitude: ${Math.floor(altitude)}m`
    })();
    infosControl.style.display = "block";

    const closeHandler = () => {
      setMeasureMode(false);
      infosControl.children[2].removeEventListener('click', closeHandler);
    }

    infosControl.children[2].addEventListener('click', closeHandler);

    // // Calculer l'angle de la polyline
    // let angle = getAngle(options.center, {lat: measureMarker.position.lat(), lng: measureMarker.position.lng()});
    // if (angle > 90 && angle < 270) {
    //   angle = 180 + angle;
    // }

    // // Placer le label au milieu de la polyline
    // const midpoint = getMidpoint(options.center, {lat: measureMarker.position.lat(), lng: measureMarker.position.lng()});

    // const distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(options.center!, {lat: measureMarker.position.lat(), lng: measureMarker.position.lng()});

    // // Créer un élément HTML pour le label
    // const labelDiv = document.createElement("div");
    // labelDiv.className = "label-container";
    // labelDiv.innerText = `${distanceMeters}m`;
    // labelDiv.style.transform = `rotate(${-angle}deg)`;
    // labelDiv.style.fontSize = '20px';
    // labelDiv.style.background = 'red';

    // // Ajouter l'élément sur la carte
    // const overlay = new google.maps.OverlayView();
    // overlay.onAdd = function () {
    //     const layer = this.getPanes()?.overlayLayer;
    //     layer?.appendChild(labelDiv);
    // };
    // overlay.draw = function () {
    //     const projection = this.getProjection();
    //     const position = projection.fromLatLngToDivPixel(new google.maps.LatLng(midpoint));
    //     labelDiv.style.left = position?.x + "px";
    //     labelDiv.style.top = position?.y + "px";
    // };
    // overlay.setMap(map);

    return () => {
      line.setMap(null);
      infosControl.children[2].removeEventListener('click', closeHandler);
    }
  }, [map, measureMarker]);

  // update options: zoom, position, marker
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useEffect(() => {
    let marker: any;

    if (map) {
      map.setOptions(options);

      console.log("water way option", options);

      if (options.center) {
        marker = new google.maps.marker.AdvancedMarkerElement({
          position: options.center,
          map,
        });
      }
    }


    return () => {
      marker && marker.setMap(null);
    };
  }, [map, options]);

  // update parcell and building polygon
  useEffect(() => {
    let buildingData: any, parcellData: any, bdnbData: any, volumes: any;
    if (map && data4HomeInfo) {
      // if (data4HomeInfo.empriseBatieParis && data4HomeInfo.empriseBatieParis.features && data4HomeInfo.empriseBatieParis.features.length > 0) {
      //     buildingData = new google.maps.Data()
      //     buildingData.addGeoJson(data4HomeInfo.empriseBatieParis)
      //     buildingData.setStyle({
      //         fillColor: "#770000",
      //         strokeColor: "#FF0000"
      //     })
      //     buildingData.setMap(map)
      // }

      if (
        data4HomeInfo.parcels &&
        data4HomeInfo.parcels.features &&
        data4HomeInfo.parcels.features.length > 0
      ) {
        parcellData = new google.maps.Data();
        parcellData.addGeoJson(data4HomeInfo.parcels);
        parcellData.setStyle({
          fillColor: "#007700",
          strokeColor: "#00FF00",
        });
        parcellData.setMap(map);
      }

      if (
        data4HomeInfo.buildings &&
        data4HomeInfo.buildings.features &&
        data4HomeInfo.buildings.features.length > 0
      ) {
        bdnbData = new google.maps.Data();
        bdnbData.addGeoJson(data4HomeInfo.buildings);
        bdnbData.setStyle({
          fillColor: "#000077",
          strokeColor: "#0000FF",
        });
        bdnbData.setMap(map);
      }

      return () => {
        buildingData && buildingData.setMap(null);
        parcellData && parcellData.setMap(null);
        bdnbData && bdnbData.setMap(null);
        volumes && volumes.setMap(null);
      };
    }
  }, [map, data4HomeInfo]);

  useEffect(() => {
    if (!map || !waterWays) { return }

    console.log("water way option", options);

    const waterWaysData = new google.maps.Data();
    waterWaysData.addGeoJson(waterWays);
    waterWaysData.setStyle({
      fillColor: "#000000",
      strokeColor: "#3364ff",
    });
    waterWaysData.setMap(map);

    // show all points in the geojson
    // const waterWaysMarkers = [];
    // for (const feature of waterWays.features) {
    //   for (let point of feature.geometry.coordinates) {
    //     const marker = new google.maps.Marker({
    //       position: {lat: point[1], lng: point[0]},
    //       icon: {
    //         strokeColor: '#000000',
    //         path: google.maps.SymbolPath.CIRCLE,
    //         scale: 5,
    //         labelOrigin: new google.maps.Point(0, 5),
    //       },
    //       map,
    //     });
    //     waterWaysMarkers.push(marker);
    //   }
    // }

    const marker = new google.maps.Marker({
      position: {lat: waterWays.closest.point[1], lng: waterWays.closest.point[0]},
      icon: {
        strokeColor: '#0000FF',
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        labelOrigin: new google.maps.Point(0, 5),
      },
      map,
    });

    return () => {
      waterWaysData && waterWaysData.setMap(null);
      marker.setMap(null);
    };


  }, [waterWays, options, map]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={{...style}} />
    </>
  );
};

export default Map;
