import { useState, useEffect } from "react";
import { Box, CircularProgress, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";

import "./weather.css";

import SearchResultBlock from "../../components/Block";
import WeatherItem from "../../components/WeatherItem";
import WeatherItemInfos from "../../components/WeatherItemInfos";

import {
  fetchWeatherAction,
  fetchWeatherRangeAction,
} from "../address/weatherSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAddress } from "../address/addressSlice";
import { selectWeatherAlert, selectWeather } from "../address/weatherSlice";
import { ResultBlockType } from "../../types";
import { weatherRisk } from "../../constants";
import { useTranslation } from "react-i18next";

const today = new Date().toISOString();

export default function WeatherSearchResultBlock({
  title,
  id,
  iconType,
}: ResultBlockType): JSX.Element {
  const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress),
    weatherAlert = useAppSelector(selectWeatherAlert),
    weather = useAppSelector(selectWeather);

  const [selectedDate, setSelectedDate] = useState<string | null>(today);
  const [selectedAlertType, setSelectedAlertType] = useState<string | null>(
    null
  );
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const { t } = useTranslation();

  const altitude =
    address &&
    address.value &&
    address.value.buildingData2.find(
      (data: { label: string; unit: string; value: string }) =>
        data.label === "Altitude"
    );

  const weatherRiskFilter =
    altitude?.value > 5
      ? weatherRisk.filter(
          (risk: { id: string; label: string }) => risk.label !== "Submersion"
        )
      : weatherRisk;

  useEffect(function () {
    dispatch(fetchWeatherRangeAction());
  }, [dispatch]);

  useEffect(
    function () {
      if (!address.value) {
        return;
      }
      if (address.value.inseePostalCodeData) {
        dispatch(
          fetchWeatherAction({
            date: selectedDate === null ? today : selectedDate,
            postalCode: address.value.inseePostalCodeData?.properties.postal_code,
          })
        );
      }
    },
    [address, selectedDate, dispatch]
  );

  useEffect(
    function () {
      if (selectedAlertType) {
        const alert = getRiskIfExist(selectedAlertType, weatherAlert.value);
        if (alert) {
          setSelectedAlert(alert);
        }
      }
    },
    [selectedAlertType, weatherAlert.value]
  );

  useEffect(
    function () {
      setSelectedDate(today);
      setSelectedAlert(null);
    },
    [address]
  );
  useEffect(
    function () {
      setSelectedAlert(null);
    },
    [selectedDate]
  );

  const renderWeatherItems = (): JSX.Element[] => {
    return weatherRiskFilter.map((item, index): JSX.Element => {
      let alert = getRiskIfExist(item.id, weatherAlert.value);
      return (
        <Grid
          item
          xs={2}
          flexDirection="column"
          alignItems="center"
          className="WeatherItem"
          key={`weather-item-risk-${index}`}
        >
          <WeatherItem
            type={item.id}
            level={alert ? alert.level : null}
            onAlertClick={(type) => setSelectedAlertType(type)}
            label={t(item.label)}
            isDisabled={false}
          />
        </Grid>
      );
    });
  };

  const getRiskIfExist = (type: string | null, alerts: any[]): any | null => {
    if (type) {
      for (let alert of alerts) {
        if (type === alert.type) {
          return alert;
        }
      }
    }
    return null;
  };

  const renderWeatherItemsNoAlerts = (): JSX.Element[] => {
    return weatherRiskFilter.map(
      (item, index): JSX.Element => (
        <Grid
          item
          xs={2}
          className="WeatherItem"
          flexDirection="column"
          alignItems="center"
          key={`weather-item-no-risk-${index}`}
        >
          <WeatherItem
            type={item.id}
            level={null}
            label={t(item.label)}
            isDisabled={address.value === undefined}
          />
        </Grid>
      )
    );
  };

  return (
    <SearchResultBlock
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <Grid
        container
        justifyContent="flex-start"
        className="WeatherBlockWrapper"
      >
        <Grid item className="DatePickerWrapper">
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue.toISOString());
                setSelectedAlertType(null);
              }}
              renderInput={(params) => <TextField {...params} />}
              minDate={weather.range.value?.min}
              maxDate={weather.range.value?.max}
              disabled={address.value === undefined}
            />
          </LocalizationProvider>
          { weatherAlert.status === 'loading' && <CircularProgress sx={{marginLeft: 2}}/> }
          </Box>
        </Grid>
        <Grid
          item
          container
          columns={10}
          alignItems="center"
          justifyContent="center"
          className="WeatherItemsWrapper"
        >
          {weatherAlert.value
            ? renderWeatherItems()
            : renderWeatherItemsNoAlerts()}
        </Grid>
        <Grid item xs={12}>
          <WeatherItemInfos
            alert={selectedAlert}
            isRisk={weatherAlert.value?.length > 0 ? true : false}
            selectedAlertType={selectedAlertType}
            alertOnItemClikedIsExist={
              getRiskIfExist(selectedAlertType, weatherAlert.value)
                ? true
                : false
            }
            isDisabled={address.value === undefined}
          />
        </Grid>
      </Grid>
    </SearchResultBlock>
  );
}
