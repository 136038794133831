import instance from "../auth/authApi";

export async function getPlace(placeId: string) {
  const response = await instance.get(`/place`, { params: { placeId } });
  return response.data;
}

export async function getPlaceSatelliteImage(placeId: string) {
  const response = await instance.get(`/place/satellite`, {
    params: { placeId },
  });
  return response.data;
}

export async function getPlaceStreetViewImages(placeId: string) {
  const response = await instance.get(`/place/street-views`, {
    params: { placeId },
  });
  return response.data;
}

export async function getHistoricMonuments(placeId: string) {
  const response = await instance.get(`/historic-monument`, {
    params: { placeId },
  });
  return response.data;
}

export async function getFireStations(placeId: string) {
  const response = await instance.get(`/fire-station`, {
    params: { placeId },
  });
  return response.data;
}

export async function getClimateRisk(placeId: string) {
  const response = await instance.get(`/risk`, { params: { placeId } });
  return response.data;
}

export async function getGeoJSONRisk({ placeId, typeRisk }: any) {
  const response = await instance.get(`/risk/${typeRisk}`, {
    params: { placeId, distance: 50000 }
  });
  return response.data;
}

export async function getBusinesses({
  lat,
  lng,
  nafCodeFilter,
  minDistance,
  maxDistance,
  resultPerPage,
  page,
}: any) {
  const response = await instance.get(`/business`, {
    params: {
      lat,
      lng,
      nafCodeFilter,
      minDistance,
      maxDistance,
      resultPerPage,
      page,
    },
  });
  return response.data;
}

export async function getBusinessNafCodes() {
  const response = await instance.get(`/business/naf-codes`);
  return response.data;
}

export async function getWeatherAlert({
  date,
  postalCode,
}: {
  date: string;
  postalCode: string;
}) {
  const response = await instance.get(`/weather/alert`, {
    params: { date, postal_code: postalCode },
  });
  return response.data;
}

export async function getWeatherAlertRange() {
  const response = await instance.get(`/weather/alert/range`);
  return response.data;
}

export async function getUserAnnotationForPlaceId(placeId: any) {
  const response = await instance.get(`/annotation`, {
    params: { placeId },
  });
  return response.data;
}

export async function createOrUpdateUserAnnotationForPlaceId({
  placeId,
  annotation,
}: any) {
  const response = await instance.put(`/annotation`, annotation, {
    params: { placeId },
  });
  return response.data;
}

export async function getPerilForCoordinate({ lat, lng }: any) {
  const response = await instance.get(`/peril`, {
    params: { lat, lng },
  });
  return response.data;
}

export async function createPeril({ newPeril }: any) {
  const response = await instance.post(`/peril`, newPeril);
  return response.data;
}

export async function updatePerilById({ id, newPeril }: any) {
  const response = await instance.post(`/peril/${id}`, newPeril);
  return response.data;
}

export async function deletePerilById({ id }: any) {
  const reponse = await instance.delete(`/peril`, {
    params: { id },
  });
  return reponse.data;
}

export async function getAlertsForMap({
  isFeatureCollection,
}: {
  isFeatureCollection: boolean;
}) {
  const response = await instance.post(`/getAlert`, { isFeatureCollection });
  return response.data;
}

export async function getAlertsForMapDay({
  isFeatureCollection,
  startDate,
  endDate,
  yellow = undefined
}: {
  isFeatureCollection: boolean;
  startDate: Date;
  endDate: Date;
  yellow?: boolean
}) {
  const response = await instance.post(`/getAlert`, {
    isFeatureCollection,
    startDate,
    endDate,
    yellow
  });
  return response.data;
}

export async function createOrUpdateAlertForMap({ operation, alert }: any) {
  const response = await instance.post(`/alert/manage`, alert, {
    params: { operation },
  });
  return response.data;
}

export async function getUserInfo() {
  const response = await instance.get(`/userInfo`);
  const res = {
    name: response.data.name,
    groups: response.data.groups,
    preferred_username: response.data.preferred_username,
    given_name: response.data.given_name,
    family_name: response.data.family_name,
    email: response.data.email,
  };
  return res;
}

export async function getListSiteSevesoByPlaceId({ placeId, distance }: any) {
  const response = await instance.get(`/risk/seveso`, {
    params: { placeId, distance },
  });
  return response.data;
}

export async function generatePdf(placeId: string, display?: string) {
  const response = await instance.get(`/pdf-generator`, {
    params: { placeId, display },
    responseType: "arraybuffer",
  });
  return { arraybuffer: response.data, status: response.status };
}

export async function uploadEventfile(formData: any) {
  const response = await instance.post(`/risk/event/upload`, formData, {headers: {'content-type': 'application/x-www-form-urlencoded'}});
  return response.data;
}

export async function getEvents({ resultPerPage, page }: { resultPerPage: number, page: number }) {
  const response = await instance.get(`/risk/event/all`, {params: { resultPerPage, page }});
  return response.data;
}

export async function uploadAddressfile(formData: any) {
  const response = await instance.post(`/address/bulk`, formData, {
    headers: {'content-type': 'application/x-www-form-urlencoded'},
    responseType: 'blob',
    timeout: 300000
  });
  return response.data;
}

export async function bulkAddressUsage() {
  const response = await instance.get(`/address/bulk/usage`);
  return response.data;
}

export async function getObservationsForDate( lat: number, lng: number, date: string ) {
  const response = await instance.get(`/weather/observation`, { params: {lat, lng, date }});
  return response.data;
}

export async function getWaterWays(placeId: string, distance?: number) {
  const response = await instance.get(`/water-way`, {
    params: { placeId, distance },
  });
  return response.data;
}

export async function getElevation(lat: number, lng: number) {
  const response = await instance.get(`/altitude`, {
    params: { lat, lng },
  });
  return response.data;
}

export async function getElevationFromGoogle(lat: number, lng: number) {
  const response = await instance.get(`/altitude/google`, {
    params: { lat, lng },
  });
  return response.data;
}