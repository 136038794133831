import Icon from "../Icon";

interface PropsType {
    type: string;
    level: string | null;
    onAlertClick?: (type: string) => void;
    label: string;
    selected: boolean;
    disabled: boolean;
}

const containerIconStyle: Record<string, string> = {
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    boxShadow: "0px 4px 16px 0px rgba(189, 189, 189, 1)",
    cursor: "pointer",
};

export default function WeatherObservationItem({type, level, label, selected, onAlertClick, disabled}: PropsType): JSX.Element {
    const setBackgroundColor = (level: string | null, isDisable: boolean): string => {
        if (selected) {
            return "blue";
        }
        return "#cdcdcd";
    };

    return (
        <>
            <button
                className="IconButton"
                onClick={onAlertClick ? () => onAlertClick(type) : undefined}
                style={{backgroundColor: "transparent", border: "none"}}
                disabled={disabled}
            >
                <Icon
                    type={type}
                    containerStyle={{
                        ...containerIconStyle,
                        backgroundColor: setBackgroundColor(level, selected),
                    }}
                />
            </button>
            <h5 style={{margin: ".5rem 0"}}>{label}</h5>
        </>
    );
}
