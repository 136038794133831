import { call, all, put, takeLatest } from 'redux-saga/effects'
import {
    getPlace,
    getPlaceSatelliteImage,
    getPlaceStreetViewImages,
    getHistoricMonuments,
    // getFireStations,
    getWaterWays,
} from './data4homeAPI'
import {
    fetchAddressAction,
    fetchSatelliteImageAction,
    fetchStreetViewImagesAction,
    // fetcHistoricMonumentsAction,
    // fetchFireStationsAction
    fetchWaterWaysAction,
} from './addressSlice'

function imgURLFromBuffer(buffer: any, format: string) {
    if (!buffer) return undefined;
    const arrayBuffer = new Uint8Array(buffer)
    // const base64Flag = 'data:' + format + ';base64,';
    // const base64Data = buffer.toString('base64');
    // return base64Flag + base64Data;
    const blob = new Blob([arrayBuffer], { type: format });
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(blob);
    return imageUrl
}

function* fetchStreetViewImages(action: any): any {
    try {
        yield put(fetchStreetViewImagesAction.pending())
        const streetViewImageResponse: any = yield call(getPlaceStreetViewImages, action.payload.place_id)
        yield put(fetchStreetViewImagesAction.fulfilled(
            streetViewImageResponse.map(function (streetView: any) {
                return imgURLFromBuffer(streetView.content.data, streetView.contentType)
            })
        ))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchStreetViewImagesAction.rejected(error.message))
    }
}

function* fetchSatelliteImage(action: any): any {
    try {
        yield put(fetchSatelliteImageAction.pending())
        const satelliteImageResponse: any = yield call(getPlaceSatelliteImage, action.payload.place_id)
        const { satelliteImage, zoomLevel, fetchedDate } = satelliteImageResponse
        yield put(fetchSatelliteImageAction.fulfilled(imgURLFromBuffer(satelliteImage.content.data, satelliteImage.contentType), zoomLevel, fetchedDate))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchSatelliteImageAction.rejected(error.message))
    }
}

// function* fetchHistoricMonuments(action: any): any {
//     try {
//         yield put(fetcHistoricMonumentsAction.pending())
//         const historicMonuments: any = yield call(getHistoricMonuments, action.payload.place_id)
//         yield put(fetcHistoricMonumentsAction.fulfilled(historicMonuments))
//         return historicMonuments
//     } catch (error: any) {
//         console.log("error", error)
//         yield put(fetcHistoricMonumentsAction.rejected(error.message))
//     }
// }

// function* fetchFireStations(action: any): any {
//     try {
//         yield put(fetchFireStationsAction.pending())
//         const fireStations: any = yield call(getFireStations, action.payload.place_id)
//         yield put(fetchFireStationsAction.fulfilled(fireStations))
//         return fireStations
//     } catch (error: any) {
//         console.log("error", error)
//         yield put(fetchFireStationsAction.rejected(error.message))
//     }
// }

function* fetchAddress(action: any): any {
    try {
        yield put(fetchAddressAction.pending())
        // fech address must happen before any other request to avoid creating multiple entries for the same address in database
        const address = yield call(getPlace, action.payload.place_id)
        const [historicMonuments/*, fireStations*/, waterWays] = yield all([
            call(getHistoricMonuments, action.payload.place_id),
            // call(getFireStations, action.payload.place_id),
            call(getWaterWays, action.payload.place_id, 5000)
        ])
        const { buildings, inZone, altitude, cityCenter, manifestationRoute, ...other } = address
        const result = {
            ...other,
            buildings,
            inZone,
            cityCenter,
            // volumes,
            // need to convert arraybuffer to url because state allow only serializable values
            // satellite: imgURLFromBuffer(satellite.data.data, satellite.contentType),
            // streetViews: street.map(function (streetView: any) {
            //     return imgURLFromBuffer(streetView.data.data, streetView.contentType)
            // }),
        }

        const buildingData1 = []

        const buildingData2 = []

        buildingData1.push({
            label: "Surface au sol calculée depuis la geoshape",
            value: buildings.features.length
                ? buildings.features[0] && buildings.features[0].properties.geoshape_computed_area
                : undefined,
            unit: 'sqrt_meter'
        })

        buildingData1.push({
            label: "Estimation surface habitable",
            value: buildings.features.length
                ? buildings.features[0] && buildings.features[0].properties.living_area_estimation
                : undefined,
            unit: 'sqrt_meter'
        })

        buildingData1.push({
            label: "Hauteur du bâtiment",
            value: buildings.features.length
                ? buildings.features[0] && buildings.features[0].properties.max_height
                : undefined,
            unit: 'meter'
        })

        if (buildings.features.length) {
            if (buildings.features[0].floor_count) {
                buildingData1.push({
                    label: "Nombre d'étage",
                    value: buildings.features[0] && buildings.features[0].floor_count,
                    unit: 'number'
                })
            } else {
                buildingData1.push({
                    label: "Estimation du nombre d'étage",
                    value: buildings.features.length
                        ? buildings.features[0] && buildings.features[0].properties.estimated_floor_count
                        : undefined,
                    unit: 'number'
                })
            }
        }

        buildingData1.push({
            label: "Année de construction",
            value: buildings.features.length
                ? buildings.features[0] && buildings.features[0].properties.construction_year
                : undefined,
            unit: 'year'
        })

        buildingData1.push({
            label: "Bâtiment dans une zone surveillée",
            value: inZone
                ? (inZone.qpv.features.length > 0 || inZone.qpvAncienDegrade.features.length > 0 || inZone.qva.features.length > 0 || inZone.zus.features.length > 0 || inZone.zfu.features.length > 0)
                : undefined,
            unit: 'bool',
            data: inZone
        })

        buildingData1.push({
            label: "Risque de manifestation",
            value: manifestationRoute !== null,
            unit: 'bool'
        })

        buildingData1.push({
            label: "Bâtiment historique à moins de 500m",
            value: historicMonuments.length
                ? historicMonuments[0].properties.dist.calculated
                : false,
            unit: 'meter_or_boolean',
            data: historicMonuments
        })

        buildingData2.push({
            label: "Altitude",
            value: altitude && Math.floor(altitude),
            unit: 'meter'
        })

        buildingData2.push({
            label: "Cours d'eau le plus proche (dans un rayon de 5km)",
            value: waterWays && waterWays.features.length ? waterWays.features[0].properties.name : undefined,
            unit: 'text'
        })

        buildingData2.push({
            label: "Distance à vol d'oiseau avec le cours d'eau le plus proche (dans un rayon de 5km)",
            value: waterWays && waterWays.features.length ? Math.floor(waterWays.features[0].properties.distance.calculated) : undefined,
            unit: 'meter'
        })

        const closest = waterWays.features.map(function(feature: any) {
            const coordinates: any = [...feature.geometry.coordinates];
            coordinates.feature = feature
            return coordinates
        }).flat().reduce(function(acc: any, point: any) {
            const [lng, lat, alt] = point;
            const distanceMetersOfPoint = google.maps.geometry.spherical.computeDistanceBetween(
                {lat: address.latitude, lng: address.longitude},
                { lat, lng }
            );
            if (acc.point === null || distanceMetersOfPoint < acc.distance) {
              return {point, distance: distanceMetersOfPoint};
            }
            return acc;
        }, {name: null, point: null, distance: null});

        const waterWayHeight = Math.floor(closest.point[2]);

        buildingData2.push({
            label: "Altitude normale (hors crue) du cours d'eau le plus proche",
            value: waterWays && waterWays.features.length
                ? waterWayHeight
                : undefined,
            unit: 'meter'
        })

        buildingData2.push({
            label: "Différence entre la hauteur à l'adresse et le niveau du cours d'eau le plus proche",
            value: waterWays && waterWays.features.length && altitude
                ? (Math.floor(altitude) - waterWayHeight)
                : undefined,
            unit: 'meter'
        })

        buildingData2.push({
            label: "Utilisation du terrain",
            value: (cityCenter && cityCenter.properties.code)
                ? cityCenter.properties.code
                : 'noInfo',
            unit: 'type_urban'
        })

        // fireStations.length && buildingData.push({
        //     label: "Caserne de pompier à proxmité",
        //     value: fireStations.length
        //         ? fireStations[0].properties.dist.calculated.toFixed(0)
        //         : false,
        //     unit: 'meter'
        // })


        yield put(fetchAddressAction.fulfilled({ ...result, buildingData1, buildingData2 }))
        yield put(fetchWaterWaysAction.fulfilled({...waterWays, closest}))
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchAddressAction.rejected(error.message))
    }
}

function* fetchWaterWays(action: any): any {
    try {
        yield put(fetchWaterWaysAction.pending())
        const waterWays: any = yield call(getWaterWays, action.payload.place_id, action.payload.distance)
        yield put(fetchWaterWaysAction.fulfilled(waterWays))
        return waterWays
    } catch (error: any) {
        console.log("error", error)
        yield put(fetchWaterWaysAction.rejected(error.message))
    }
}

function* adressSaga() {
    yield takeLatest(fetchAddressAction, fetchAddress);
    yield takeLatest(fetchSatelliteImageAction, fetchSatelliteImage);
    yield takeLatest(fetchStreetViewImagesAction, fetchStreetViewImages);
    // yield takeLatest(fetcHistoricMonumentsAction, fetchHistoricMonuments);
    // yield takeLatest(fetchFireStationsAction, fetchFireStations);
    yield takeLatest(fetchWaterWaysAction, fetchWaterWays);
}

export default adressSaga;