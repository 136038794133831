import { useState, useEffect, Fragment } from "react";
import { useSnackbar } from "notistack";
import moment, { Moment } from 'moment';
import _ from "lodash";

import { useAppSelector/*, useAppDispatch*/ } from "../../app/hooks";
import { selectAddress } from "../address/addressSlice";
import { ResultBlockType } from "../../types";
import { useTranslation } from "react-i18next";

import { getObservationsForDate } from '../address/data4homeAPI';

import { CircularProgress, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/fr";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';

import SearchResultBlock from "../../components/Block";
import WeatherObservationItem from "../../components/WeatherObservationItem";

const today = moment();

const StyledLi = function(props: any) {
  const { children, ...others } = props;
  return (
    <li style={{display: "flex"}} {...others}>{children}</li>
  )
}

const FirstCol = function(props: any) {
  const { children, ...others } = props;
  return (
    <span style={{flex: 1, paddingLeft: 12}} {...others}>{children}</span>
  )
}

const ValCol = function(props: any) {
  const { children, ...others } = props;
  return (
    <span style={{width: "100px"}} {...others}>{children}</span>
  )
}

const weatherRisk = [
  { id: "THUNDER", label: "weatherLabel.thunder" },
  { id: "WINDS", label: "weatherLabel.winds" },
  { id: "SNOW", label: "weatherLabel.snow" },
  { id: "TEMPERATURE", label: "weatherLabel.temperature" },
  { id: "HEAVY_RAIN", label: "weatherLabel.heavyRain" },
  { id: "ICE", label: "weatherLabel.ice" },
  { id: "FLOOD", label: "weatherLabel.flood" },
  { id: "POLLUTION", label: "weatherLabel.pollution" },
  { id: "SUBMERSION", label: "weatherLabel.submersion" },
];

export default function WeatherObservationsResultBlock({
  title,
  id,
  iconType,
}: ResultBlockType): JSX.Element {
  // const dispatch = useAppDispatch();
  const address = useAppSelector(selectAddress);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(today);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [weatherData, setWeatherData] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [weatherDataLoading, setWeatherDataLoading] = useState<boolean>(false);

  useEffect(
    function () {
      if (!address.value || !selectedDate) {
        return;
      }

      const { latitude: lat, longitude: lng } = address.value;
      setWeatherDataLoading(true);
      setSelectedType(null);
      getObservationsForDate(lat, lng, selectedDate?.toISOString())
      .then(function(data) {
        setWeatherData(data);
      })
      .catch(function(exception) {
        enqueueSnackbar(
          "L'interrogation des observations a échoué",
          { variant: "error" }
        );
      }).finally(function() {
        setWeatherDataLoading(false);
      })
      // dispatch(
      //   fetchWeatherAction({
      //     date: selectedDate === null ? today : selectedDate,
      //     postalCode: address.value.inseePostalCodeData?.codePostal,
      //   })
      // );
    },
    [address, selectedDate, enqueueSnackbar/*, dispatch*/]
  );

  useEffect(
    function () {
      setSelectedDate(today);
    },
    [address]
  );

  const weatherTypeHasAlert = function(type: any, data: any) {
    if (!data) {
      return true;
    }

    const alerts = data.alerts.filter(function(alert: any) {
      return alert.type === type
    });

    if (alerts.length === 0) {
      return false;
    }

    return true;
  }

  const weatherTypeHasValue = function(type: any, data: any) {
    if (!data) {
      return true;
    }

    const observations = data.observations;

    if (!weatherDataDict[type]) {
      return false;
    }

    let hasValue = weatherDataDict[type].filter(function(spec: any) {
      return spec.value.type === 'observation';
    }).map(function(spec: any) {
      const value = spec.value;
      /* special cases */
      if ((type === 'HEAVY_RAIN' || type === 'SNOW') && _.get(observations, value.path, null) === 0) {
        return false;
      }
      return _.get(observations, value.path, null) !== null
    })

    hasValue = hasValue.reduce(function(acc: boolean, value: boolean) {
      return acc && value
    }, hasValue.length > 0);

    return hasValue
  }

  const renderWeatherItems = (): (JSX.Element|null)[] => {
    return weatherRisk.map((item, index): JSX.Element|null => {
      if (!weatherTypeHasAlert(item.id, weatherData) && !weatherTypeHasValue(item.id, weatherData)) {
        return null;
      }
      return (
        <Grid
          item
          xs={2}
          flexDirection="column"
          alignItems="center"
          className="WeatherItem"
          key={`weather-item-risk-${index}`}
        >
          <WeatherObservationItem
            type={item.id}
            level={null}
            onAlertClick={(type) => setSelectedType(type)}
            label={t(item.label)}
            selected={selectedType === item.id}
            disabled={!weatherData}
          />
        </Grid>
      );
    });
  };

  // {
  //   "features": [
  //       {
  //           "geometry": {
  //               "type": "Point",
  //               "coordinates": [
  //                   2.2945,
  //                   48.858333
  //               ]
  //           },
  //           "properties": {
  //               "validity_time": "2025-02-11T00:00:00.000Z",
  //               "temperature": 0.8000000000000114,
  //               "windDirection": 270,
  //               "windSpeed": 28.08,
  //               "windGustDirection": 270,
  //               "windGustSpeed": 46.440000000000005,
  //               "precipitation": null,
  //               "snow": null
  //           },
  //           "type": "Feature"
  //       }
  //   ],
  //   "properties": {
  //       "station": {
  //           "altitude": "330",
  //           "calculated_distance": 1989.9618602155244,
  //           "id": "75107005",
  //           "name": "TOUR EIFFEL",
  //           "latitude": 48.858333,
  //           "longitude": 2.2945
  //       },
  //       "avg_temperature": 0.8000000000000114,
  //       "min_temperature": 0.8000000000000114,
  //       "max_temperature": 0.8000000000000114,
  //       "avg_windDirection": 270,
  //       "avg_windSpeed": 28.08,
  //       "min_windSpeed": 28.08,
  //       "max_windSpeed": 28.08,
  //       "avg_windGustDirection": 270,
  //       "min_windGustSpeed": 46.440000000000005,
  //       "max_windGustSpeed": 46.440000000000005,
  //       "total_precipitation": 0,
  //       "total_snow": 0
  //   },
  //   "type": "FeatureCollection"
  // }

  return (
    <SearchResultBlock
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
      title={title}
      iconType={iconType}
      id={id}
    >
      <Box sx={{flex: 1}}>
        <Box sx={{display: "flex", flexDirection: "row", paddingLeft: 8, paddingRight: 8, alignItems: "center"}}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue: Moment | null) => {
                setSelectedDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              // minDate={weather.range.value?.min}
              // maxDate={weather.range.value?.max}
              disabled={address.value === undefined}
            />
          </LocalizationProvider>
          { weatherDataLoading && <CircularProgress sx={{marginLeft: 2}}/> }
          {/* <p style={{flex: 1, marginLeft: 8}}>Les conditions sont observées pour les 2 jours précédents et les 2 jours suivants la date</p> */}
        </Box>
        <Grid
          item
          container
          columns={10}
          alignItems="center"
          justifyContent="flex-start"
        >
          {renderWeatherItems()}
        </Grid>
        <Divider sx={{height: 3, backgroundColor: "#EEEEEE", flex: 1, marginTop: 0, marginBottom: 3, marginX: 4, border: "none"}}/>
        {
          selectedType && weatherDataDict[selectedType] &&
          <Box sx={{flex: 1, paddingLeft: 4, paddingRight: 4}}>
            <p style={{fontSize: "1.5rem", textTransform: "uppercase", marginTop: 0, marginBottom: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 12, backgroundColor: "#ece9fd"}}>Données techniques</p>
            {weatherData && renderWeatherData(selectedType, weatherData)}
          </Box>
        }


      </Box>
    </SearchResultBlock>
  );
}

const renderWeatherDataValue = function(data: any, dataSpec: any, type: string) {
  let value, unit = dataSpec.value.unit;
  if (dataSpec.value.type === 'observation') {
    const observations = data.observations
    value = _.get(observations, dataSpec.value.path);
  } else {
    const alerts = data.alerts.filter(function(alert: any) {
      return alert.type === type
    });

    if (alerts.length === 0) {
      return <span>N/A</span>;
    }

    const alert = alerts[0];
    value = _.get(alert, dataSpec.value.path);
  }
  switch (unit) {
    case "degree":
      return (
        <Fragment>
          <span>{Math.round(value)}</span>
          <span>°</span>
        </Fragment>
      )
    case "boolean":
      return (
        <Fragment>
          <span style={{fontWeight: value ? "bolder" : "normal", marginRight: "10px"}}>Oui</span>
          <span style={{fontWeight: !value ? "bolder" : "normal"}}>Non</span>
        </Fragment>
      )
    case "text":
      return (
        <Fragment>
          <span>{value}</span>
        </Fragment>
      )
    default:
      return (
        <Fragment>
          <span>{Math.round(value)}</span>
          <span>{unit}</span>
        </Fragment>
      )
  }
}

const renderLabel = function(label: string, parameters: any[], data: any) {
  if (!parameters || parameters.length === 0) {
    return label;
  }
  let labelWithParameters = label;
  for (let parameter of parameters) {
    if (typeof parameter === 'object') {
      const parameterValue = _.get(data, parameter.path, parameter.value);
      labelWithParameters = labelWithParameters.replace('%', parameter.transform(parameterValue));
    } else {
      const parameterValue = _.get(data, parameter);
      labelWithParameters = labelWithParameters.replace('%', parameterValue);
    }
  }

  return labelWithParameters;
}

const renderWeatherData = function(type: string, data: any) {
  if (!data) {
    return null;
  }

  return (
    <ul style={{listStyle: "inside", padding: 0}}>
      {weatherDataDict[type].map(function(dataSpec: any, index: number) {
        return (
          <StyledLi key={`weatherSpec-${type}-${index}`}>
            <FirstCol>{renderLabel(dataSpec.label, dataSpec.labelParameters, data)}</FirstCol>
            <ValCol>
              {renderWeatherDataValue(data, dataSpec, type)}
            </ValCol>
          </StyledLi>
        )
      })}

      {/* <StyledLi>
        <FirstCol>Vitesse max rafales à l’adresse postale</FirstCol>
        <span>110km/h</span>
      </StyledLi>
      <StyledLi>
        <FirstCol>Direction du vent des rafales les plus fortes</FirstCol>
        <span>110km/h</span>
      </StyledLi>
      <StyledLi>
        <FirstCol>Vitesse moyenne du vent à la station la plus proche sur la durée voulue (24 heures +/- 72 heures) </FirstCol>
        <span>110km/h</span>
      </StyledLi>
      <StyledLi>
        <FirstCol>Orientation dominante du vent</FirstCol>
        <span>110km/h</span>
      </StyledLi>
      <StyledLi>
        <FirstCol>Tornade</FirstCol>
        <span>110km/h</span>
      </StyledLi> */}
    </ul>
  )
}

const metersToKilometers = function(valueInMeters: number) {
  return (valueInMeters / 1000).toFixed(1);
}

const weatherDataDict: any = {
  'WINDS': [{
    'label': 'Vitesse maximum des rafales à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.windGustSpeed',
      'unit': 'km/h'
    }
  }, {
    'label': 'Vitesse maximum des rafales des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.max_windGustSpeed',
      'unit': 'km/h'
    }
  }, {
    'label': 'Vitesse moyenne du vent des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.avg_windSpeed',
      'unit': 'km/h'
    }
  }/*, {
    'label': 'Vitesse max rafales à l’adresse postale',
    'value': {
      'type': 'observation',
      'path': 'properties.max_windGustSpeed',
      'unit': 'km/h'
    }
  }*/, {
    'label': 'Orientation dominante des rafales',
    'value': {
      'type': 'observation',
      'path': 'properties.avg_windGustDirection',
      'unit': 'degree'
    }
  }, {
    'label': 'Orientation dominante du vent',
    'value': {
      'type': 'observation',
      'path': 'properties.avg_windDirection',
      'unit': 'degree'
    }
  }/*, {
    'label': 'Tornade',
    'value': {
      'type': 'observation',
      'path': 'properties.tornado',
      'unit': 'boolean'
    }
  }*/],
  'HEAVY_RAIN': [{
    'label': 'Précipitations à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.precipitation',
      'unit': 'mm'
    }
  }, {
    'label': 'Accumulation de précipitations sur la commune',
    'value': {
      'type': 'alert',
      'path': 'rain',
      'unit': 'mm'
    }
  }],
  'ICE': [{
    'label': 'Temperature minimale à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.min_temperature',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature du point de rosée à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.dew_point',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature minimale des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.min_temperature',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature moyenne du point de rosée des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.avg_dew_point',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature minimale du point de rosée des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.min_dew_point',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature maximale du point de rosée des stations de référence dans un rayon de % kms',
    'labelParameters': [{value: 30000, transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.max_dew_point',
      'unit': 'degree'
    }
  }],
  'TEMPERATURE': [{
    'label': 'Temperature à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.temperature',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature maximale à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.max_temperature',
      'unit': 'degree'
    }
  }, {
    'label': 'Temperature minimale à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.min_temperature',
      'unit': 'degree'
    }
  }],
  'SNOW': [{
    'label': 'Neige à la station météo la plus proche : station de %, située à % kms',
    'labelParameters': ['observations.properties.closestStation.station.name', {path: 'observations.properties.closestStation.station.calculated_distance', transform: metersToKilometers}],
    'value': {
      'type': 'observation',
      'path': 'properties.closestStation.values.snow',
      'unit': 'mm'
    }
  }],
  'THUNDER': [{
    'label': 'Présence de grêle',
    'value': {
      'type': 'alert',
      'path': 'hail',
      'unit': 'boolean'
    }
  }],
  'FLOOD': [{
    'label': 'Section de cours d\'eau',
    'value': {
      'type': 'alert',
      'path': 'section',
      'unit': 'text'
    }
  }],
  'POLLUTION': [{
    'label': 'Niveau 03',
    'value': {
      'type': 'alert',
      'path': 'polluants[0].level',
      'unit': 'text'
    }
  }, {
    'label': 'Niveau NO2',
    'value': {
      'type': 'alert',
      'path': 'polluants[1].level',
      'unit': 'text'
    }
  }, {
    'label': 'Niveau PM10',
    'value': {
      'type': 'alert',
      'path': 'polluants[2].level',
      'unit': 'text'
    }
  }, {
    'label': 'Niveau PM25',
    'value': {
      'type': 'alert',
      'path': 'polluants[3].level',
      'unit': 'text'
    }
  }],
  'SUBMERSION': [{
    'label': 'Hauteur des vagues',
    'value': {
      'type': 'alert',
      'path': 'wave_height',
      'unit': 'm'
    }
  }, {
    'label': 'Hauteur de houle',
    'value': {
      'type': 'alert',
      'path': 'swell_height',
      'unit': 'm'
    }
  }, {
    'label': 'Hauteur Total',
    'value': {
      'type': 'alert',
      'path': 'total_height',
      'unit': 'm'
    }
  }]
}

// TODO: filter out snow and rain values = 0
// TODO: test if we can limit ice to when it's cold and may be temperature close/below dew point