/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useMemo, Fragment} from "react";
import moment, { Moment } from "moment";
// import _ from 'lodash';

import {useAppSelector, useAppDispatch} from "../../app/hooks";
import {useSnackbar} from "notistack";
import {fetchAlertMapDayAction, selectAlertMap} from "../alertMap/alertMapSlice";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import {/*Backdrop, */Divider, FormControlLabel, FormGroup, Switch, TableHead,/* Theme,*/ Typography} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import {red, yellow} from "@mui/material/colors";
// import {Modal} from "@mui/base/Modal";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import 'moment/locale/fr';

import "./D4Weather.css";
import "./D4Weather2.css";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import AlertTable from './AlertTable'

import {AlertItemCheckBoxType} from "../../types";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import AlertItemCheckbox from "./AlertItemCheckbox";
import "react-datepicker/dist/react-datepicker.css";

import AppBar from "../address/AppBar";
import AlertAccordionList from "./AlertAccordionList";

import useMapboxHandler from "./hooks/useMapboxHandler";

const drawerWidth = 350;

const isFeatureCollection: boolean = true;
const filterYellow: (feature: any) => boolean = (feature: any) => feature.properties.level === "JAUNE" || feature.properties.level === "Dégradé";
const filterOrange: (feature: any) => boolean = (feature: any) => feature.properties.level === "ORANGE" || feature.properties.level === "Mauvais";
const filterRed: (feature: any) => boolean = (feature: any) => feature.properties.level === "ROUGE" || feature.properties.level === "Très mauvais" || feature.properties.level === "Extrêmement mauvais";

const RedSwitch = styled(Switch)(({theme}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: red[600],
        "&:hover": {
            backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: red[600],
    },
}));

const YellowSwitch = styled(Switch)(({theme}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: yellow[600],
        "&:hover": {
            backgroundColor: alpha(yellow[600], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: yellow[600],
    },
}));

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            // hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, visibility: value === index ? "visible" : "hidden"}}
            {...other}
        >
            {children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function D4Weather() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const alertMap = useAppSelector(selectAlertMap);
    const {enqueueSnackbar} = useSnackbar();
    const [data, setData] = useState<any>();

    // const [startDate, setStartDate] = useState(moment());
    // const [endDate, setEndDate] = useState(moment());

    const [selectedDate, setSelectedDate] = useState<Moment>(moment().startOf('day'));
    const [previsouSelectedDate, setPreviousSelectedDate] = useState<Moment>(moment().startOf('day'));

    const [isCheckedYellow, setIsCheckedYellow] = useState<boolean>(true);
    const [isCheckedOrange, setIsCheckedOrange] = useState<boolean>(true);
    const [isCheckedRed, setIsCheckedRed] = useState<boolean>(true);
    const [withYellow, setWithYellow] = useState<boolean>(false);
    const [previousWithYellow, setPreviousWithYellow] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<((feature: any) => boolean)[]>([filterYellow, filterOrange, filterRed]);
    // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {mapRef, alert, listCheckBox, allChecked, initMapWeatherRisk, onLoadMapWeather, handleLayerAlert, setListCheckBox, setAllChecked} =
        useMapboxHandler(function(listCheckBox: any) {
            setLocalListCheckBox(listCheckBox);
        });
    const [localListCheckBox, setLocalListCheckBox] = useState<any>(listCheckBox);

    useEffect(() => {
        dispatch(fetchAlertMapDayAction(
            isFeatureCollection,
            selectedDate.clone().subtract(1, 'day').utc().format("YYYY-MM-DD"),
            selectedDate.clone().add(1, 'day').utc().format("YYYY-MM-DD"),
            withYellow ? true : undefined
        ));

        initMapWeatherRisk(mapRef);
        if (mapRef.current) {
            onLoadMapWeather(mapRef);
        }
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, []);

    // useEffect(() => {
    //     dispatch(fetchAlertMapDayAction(
    //         isFeatureCollection,
    //         selectedDate.clone().subtract(1, 'day').utc().format("YYYY-MM-DD"),
    //         selectedDate.clone().add(1, 'day').utc().format("YYYY-MM-DD")
    //     ));
    // }, [selectedDate]);

    const handleSearchButtonClicked = (event: any) => {
        setPreviousWithYellow(withYellow);
        setPreviousSelectedDate(selectedDate.clone());
        dispatch(fetchAlertMapDayAction(
            isFeatureCollection,
            selectedDate.clone().subtract(1, 'day').utc().format("YYYY-MM-DD"),
            selectedDate.clone().add(1, 'day').utc().format("YYYY-MM-DD"),
            withYellow ? true : undefined
        ));
    }

    const didSearchValueChanged = function() {
        const didChange = withYellow !== previousWithYellow || !selectedDate.isSame(previsouSelectedDate);
        return didChange;
    }

    useEffect(() => {
        if (alertMap.error) {
            enqueueSnackbar("L'interrogation des alerts a échoué", {variant: "error"});
        }
        if (alertMap.value) {
            const filterData = {
                type: alertMap.value.type,
                features: [...alertMap.value.features],
            };
            filterData.features = filterData.features.filter((feature: any) => selectedFilter.some((filter) => filter(feature)));
            setData(filterData);
        }
        console.log("filter changed");
    }, [alertMap, selectedFilter]);

    useEffect(() => {
        const newFilter = [
            isCheckedYellow ? filterYellow : undefined,
            isCheckedOrange ? filterOrange : undefined,
            isCheckedRed ? filterRed : undefined,
        ].filter(Boolean) as ((feature: any) => boolean)[];
        setSelectedFilter(newFilter);
    }, [isCheckedYellow, isCheckedRed, isCheckedOrange]);

    useEffect(() => {
        if (data && mapRef.current) {
            const alertSource: any = mapRef.current.getSource("alerts");
            if (alertSource) {
                alertSource.setData(data);
            }
        }
    }, [data]);

    useEffect(() => {
        setAllChecked(listCheckBox.every((item) => item.isShow === true));
    }, [listCheckBox]);

    // const handleChangeDateRange = ({startDate, endDate}: {startDate: Date; endDate: Date}) => {
    //     setStartDate(moment(startDate));
    //     setEndDate(moment(startDate).add(2, "days"));
    // };

    const handleAllCheckboxChange = () => {
        const toggledState = !allChecked;
        setAllChecked(toggledState);

        if (toggledState || (!toggledState && listCheckBox.every((item) => item.isShow))) {
            const updatedListCheckBoxAlert = listCheckBox.map((item) => ({
                ...item,
                isShow: toggledState,
            }));
            updatedListCheckBoxAlert.forEach((item) => {
                handleLayerAlert(item.id, toggledState);
            });
            setListCheckBox(updatedListCheckBoxAlert);
            setLocalListCheckBox(updatedListCheckBoxAlert);
        }
    };

    const handleCheckboxYellow = () => {
        setIsCheckedYellow(!isCheckedYellow);
    };

    const handleCheckboxOrange = () => {
        setIsCheckedOrange(!isCheckedOrange);
    };

    const handleCheckboxRed = () => {
        setIsCheckedRed(!isCheckedRed);
    };

    const handleAlertTypeFilterChanged = (layerId: string, isChecked: boolean) => {
        handleLayerAlert(layerId, isChecked);
    };

    // const handleModalClose = () => setIsModalOpen(false);

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    };

    // TODO: this should be out of the component for permformance reason
    // and selectedDates props should be use instead of selectedDate state
    const renderWeekPickerDay = (
        date: Moment,
        selectedDates: Array<Moment | null>,
        pickersDayProps: PickersDayProps<Moment>,
    ) => {
        if (!selectedDate) {
          return <PickersDay disableMargin {...pickersDayProps} />;
        }

        const start = selectedDate.clone().subtract(1, 'day');
        const end = selectedDate.clone().add(1, 'day');

        const dayIsBetween = date.isBetween(start, end, 'day', '[]');
        const isFirstDay = date.isSame(start, 'day');
        const isLastDay = date.isSame(end, 'day');

        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    const selectedDates = useMemo(function() {
        return [
            selectedDate.clone().startOf('day').subtract('1', 'day').toISOString(),
            selectedDate.clone().startOf('day').toISOString(),
            selectedDate.clone().startOf('day').add('1', 'day').toISOString(),
        ]
    }, [selectedDate]);

    const alertTableData = useMemo(function() {
        if (!data) { return {}; }
        const alertDataMap = data.features.reduce(function(acc: any, item: any) {
            const alert = item.properties;
            if (!acc["byType"][alert.type]) {
                acc["byType"][alert.type] = selectedDates.reduce(function(acc: any, item) {
                    acc[item] = {};
                    return acc;
                }, {});
            }

            for (let date of selectedDates) {
                const expectedAtDate = moment.utc(alert.expected_at);
                // TODO: observed end for flood
                const momentDate = moment(date).utc();
                const startAtDate = expectedAtDate.isBetween(momentDate.clone().startOf('day'), momentDate.clone().endOf('day'), 'days', "[]")
                let endAtDate = false, surroundDate = false, stillPresent = false;
                if (alert.estimated_end) {
                    const expectedEndDate = moment.utc(alert.estimated_end);
                    endAtDate = expectedEndDate.isBetween(momentDate.clone().startOf('day'), momentDate.clone().endOf('day'), 'days', "[]")
                    surroundDate = momentDate.isBetween(expectedAtDate, expectedEndDate, 'days', "[]")
                } else if (alert.observed_end) {
                    const expectedEndDate = moment.utc(alert.observed_end);
                    endAtDate = expectedEndDate.isBetween(momentDate.clone().startOf('day'), momentDate.clone().endOf('day'), 'days', "[]")
                    surroundDate = momentDate.isBetween(expectedAtDate, expectedEndDate, 'days', "[]")
                } else {
                    stillPresent = expectedAtDate.isBefore(momentDate); // flood might have expected without expected end or duration if still "occuring"
                }
                const matchDate = (startAtDate || endAtDate || surroundDate || stillPresent )

                if (matchDate) {
                    if (!acc["byType"][alert.type][date][alert.level]) {
                        acc["byType"][alert.type][date][alert.level] = [];
                    }

                    acc["byType"][alert.type][date][alert.level].push(alert);
                }
            }

            // if (!acc["byPostalCode"][alert.postal_code]) {
            //     acc["byPostalCode"][alert.postal_code] = selectedDates.reduce(function(acc: any, item) {
            //         acc[item] = [];
            //         return acc;
            //     }, {});
            // }

            // for (let date of selectedDates) {
            //     const expectedAtDate = moment(alert.expected_at);
            //     const expectedEndDate = moment(alert.expected_at);
            //     const matchDate = (
            //         (expectedAtDate.isBetween(moment(date).startOf('day'), moment(date).endOf('day'), 'days', "[]")) ||
            //         (expectedEndDate.isBetween(moment(date).startOf('day'), moment(date).endOf('day'), 'days', "[]")) ||
            //         (moment(date).isBetween(expectedAtDate, expectedEndDate), 'days', "[]")
            //     )

            //     if (matchDate) {
            //         acc["byPostalCode"][alert.postal_code][date].push(alert);
            //     }
            // }

            return acc;
        }, {byType:{}, byPostalCode: {}});
        return alertDataMap;
    }, [data]);

    const requestLoading = alertMap.status === "loading";

    return (
        <Box sx={{height: "100%", backgroundColor: "white"}}>
            {/* <StyledModal open={isModalOpen} onClose={handleModalClose} slots={{backdrop: StyledBackdrop}}>
                <Box sx={style}>
                    <AlertEditor onClose={handleModalClose} />
                </Box>
            </StyledModal> */}
            <Box sx={{paddingTop: 8, display: "flex", height: "100%"}}>
                <CssBaseline />
                <AppBar title={t("applicationMenu.menuItem.data4weather")} position="fixed" sx={{zIndex: (theme: any) => theme.zIndex.drawer + 1}} />
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: "border-box"},
                    }}
                >
                    <Box sx={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 8, paddingBottom: 4}}>
                        <Typography>{t("weatherScreen.requestFilterLabel")}</Typography>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"fr"}>
                            <CalendarPicker
                                maxDate={moment().add(3, 'days')}
                                date={selectedDate}
                                onChange={(newValue) => {
                                    if (!newValue) { return }
                                    setSelectedDate(newValue);
                                }}
                                renderDay={renderWeekPickerDay}
                                showDaysOutsideCurrentMonth
                            />
                            <Box sx={{display: "flex", alignItems: "center", paddingBottom: 2, paddingLeft: 4, paddingRight: 4}}>
                                <Typography sx={{flex: 1}}>{t("weatherScreen.loadYellowAlertLabel")}</Typography>
                                <Checkbox checked={withYellow} onChange={(event) => setWithYellow(event.target.checked)}/>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Button
                                    variant="contained"
                                    disabled={requestLoading || !didSearchValueChanged()}
                                    onClick={handleSearchButtonClicked}
                                    sx={{width: 130}}
                                >
                                    {requestLoading ? <CircularProgress size={24} sx={{ color: "white" }}/> : t('searchLabel')}
                                </Button>
                            </Box>
                        </LocalizationProvider>
                        {/* <DatePickerRange
                            disableEndDate
                            startDate={startDate.toDate()}
                            endDate={endDate.toDate()}
                            onChange={handleChangeDateRange}
                            onNextStartDate={() =>
                                handleChangeDateRange({startDate: startDate.add(1, "days").toDate(), endDate: endDate.toDate()})}
                            onPrevStartDate={() =>
                                handleChangeDateRange({startDate: startDate.subtract(1, "days").toDate(), endDate: endDate.toDate()})
                            }
                        /> */}
                    </Box>
                    <Divider/>
                    <Box sx={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 2}}>
                        <Typography>{t("weatherScreen.localFilterLabel")}</Typography>
                        <div id="geocoder" style={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 10}} />
                        <label style={{display: "flex", alignItems: "center", paddingLeft: "12px", paddingTop: "5px", justifyContent: "flex-end"}}>
                            <span style={{flex: 5, fontSize: 15, textAlign: "right"}}>Tous</span>
                            <Checkbox style={{flex: 1}} name="tous" size="small" checked={allChecked} onChange={handleAllCheckboxChange} />
                        </label>
                        {listCheckBox.map((item: AlertItemCheckBoxType, index: number) => {
                            return <AlertItemCheckbox key={`alert_item_${index}`} item={item} handleCheckboxChange={handleAlertTypeFilterChanged} />;
                        })}
                    </Box>
                    <Box sx={{paddingBottom: "10px"}}>
                        <FormGroup sx={{display: "flex", padding: "12px 20px 12px 0", flexDirection: "row", justifyContent: "space-between"}}>
                            <FormControlLabel
                                control={<YellowSwitch size="small" checked={isCheckedYellow} onChange={handleCheckboxYellow} />}
                                label="Jaune"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={<Switch color="warning" size="small" checked={isCheckedOrange} onChange={handleCheckboxOrange} />}
                                label="Orange"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                control={<RedSwitch size="small" checked={isCheckedRed} onChange={handleCheckboxRed} />}
                                label="Rouge"
                                color="warning"
                                labelPlacement="start"
                            />
                        </FormGroup>
                        {/* <div className="buttonCreate">
                            <Button onClick={handleModalOpen} variant='contained' size='small' sx={{backgroundColor: '#1C5782', fontWeight: 600}}>
                                Créer une alert
                            </Button>
                        </div> */}
                    </Box>
                    {/* <Box sx={{textAlign: "center", paddingBottom: "10px"}}>
                        <Button variant="contained">Apply</Button>
                    </Box> */}
                    <Divider/>
                    <Box sx={{paddingLeft: "12px", paddingRight: "12px", paddingTop: 2}}>
                        <Typography sx={{marginBottom: 2}}>{t("weatherScreen.alertDetails")} {previsouSelectedDate.clone().subtract(1, 'day').format("DD/MM/YYYY")} - {previsouSelectedDate.clone().add(1, 'day').format("DD/MM/YYYY")}</Typography>
                        <AlertAccordionList alertItemList={alert} />
                    </Box>
                </Drawer>
                <Box sx={{flex: 1, display: "flex", flexDirection: "column"}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label={t('weatherScreen.tabs.map')} {...a11yProps(0)} value={0}/>
                            <Tab label={t('weatherScreen.tabs.alertSummary')} {...a11yProps(1)} value={1}/>
                            <Tab label={t('weatherScreen.tabs.alertTable')} {...a11yProps(2)} value={2}/>
                        </Tabs>
                    </Box>
                    <Box sx={{flex: 1, position: "relative"}}>
                        <CustomTabPanel value={tabValue} index={0}>
                            <div className="map-container" id="mapBox" style={{height: "100%"}}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <TableContainer sx={{ paddingLeft: 2, paddingRight: 2, backgroundColor: "white" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow key={`byType-header`}>
                                            <TableCell key={`byType-header-0`}>Type</TableCell>
                                            <TableCell key={`byType-header-1`} colSpan={4} sx={{textAlign: "center"}}>{selectedDate.clone().startOf('day').subtract('1', 'day').format("DD/MM/YYYY")}</TableCell>
                                            <TableCell key={`byType-header-2`} colSpan={4} sx={{textAlign: "center"}}>{selectedDate.clone().startOf('day').format("DD/MM/YYYY")}</TableCell>
                                            <TableCell key={`byType-header-3`} colSpan={4} sx={{textAlign: "center"}}>{selectedDate.clone().startOf('day').add('1', 'day').format("DD/MM/YYYY")}</TableCell>
                                        </TableRow>
                                        <TableRow key={`byType-sub-header`}>
                                            <TableCell key={`byType-sub-header-0`}></TableCell>
                                            <TableCell key={`byType-sub-header-1`}>Total</TableCell>
                                            <TableCell key={`byType-sub-header-2`} sx={{color: "#fdd835"}}>Jaune</TableCell>
                                            <TableCell key={`byType-sub-header-3`} sx={{color: "#ed6c02"}}>Orange</TableCell>
                                            <TableCell key={`byType-sub-header-4`} sx={{color: "#e53935"}}>Rouge</TableCell>

                                            <TableCell key={`byType-sub-header-5`}>Total</TableCell>
                                            <TableCell key={`byType-sub-header-6`} sx={{color: "#fdd835"}}>Jaune</TableCell>
                                            <TableCell key={`byType-sub-header-7`} sx={{color: "#ed6c02"}}>Orange</TableCell>
                                            <TableCell key={`byType-sub-header-8`} sx={{color: "#e53935"}}>Rouge</TableCell>

                                            <TableCell key={`byType-sub-header-9`}>Total</TableCell>
                                            <TableCell key={`byType-sub-header-10`} sx={{color: "#fdd835"}}>Jaune</TableCell>
                                            <TableCell key={`byType-sub-header-11`} sx={{color: "#ed6c02"}}>Orange</TableCell>
                                            <TableCell key={`byType-sub-header-12`} sx={{color: "#e53935"}}>Rouge</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        alertTableData.byType && Object.keys(alertTableData.byType).sort().map(function(key: string, lineIndex: number) {
                                            const dates = (alertTableData as any)["byType"][key];
                                            return (
                                                <TableRow key={`byType-row-${lineIndex}`}>
                                                    <TableCell key={`byType-line-${lineIndex}-cell-${key}`}>{key}</TableCell>
                                                {
                                                    selectedDates.map(function(dateKey: string, cellIndex: number) {
                                                        const date = dates[dateKey]
                                                        if (!date) {
                                                            return (
                                                            <Fragment>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-total`} sx={{textAlign: "center"}}>0</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-yellow`} sx={{color: "#fdd835", textAlign: "center"}}>0</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-orange`} sx={{color: "#ed6c02", textAlign: "center"}}>0</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-red`} sx={{color: "#e53935", textAlign: "center"}}>0</TableCell>
                                                            </Fragment>
                                                            )
                                                        }
                                                        const total = Object.keys(date).reduce(function(acc: any, levelKey: any) {
                                                            return acc + date[levelKey].length
                                                        }, 0);
                                                        return (
                                                            <Fragment>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-total`} sx={{textAlign: "center"}}>{total}</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-yellow`} sx={{color: "#fdd835", textAlign: "center"}}>{date["JAUNE"] ? date["JAUNE"].length : 0}</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-orange`} sx={{color: "#ed6c02", textAlign: "center"}}>{date["ORANGE"] ? date["ORANGE"].length : 0}</TableCell>
                                                                <TableCell key={`byType-line-${lineIndex}-cell-${cellIndex}-red`} sx={{color: "#e53935", textAlign: "center"}}>{date["ROUGE"] ? date["ROUGE"].length : 0}</TableCell>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                </TableRow>
                                            )
                                        })
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <AlertTable data={data} typeFilters={localListCheckBox}/>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
