import axios from "axios";
import TokenService from "./tokenService";
import AuthService from "./authService";
import { store } from "../../app/store";
import { fetchUserInfoAction } from "../userInfo/userInfoSlice";

const handleLogout = () => {
  AuthService.logout();
  window.location.href = "/auth/signin?sessionExpired=true";
};

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 0
});

instance.interceptors.request.use(
  (config) => {
    const access_token = TokenService.getLocalAccessToken();
    if (access_token && config && config.headers) {
      config.headers["authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (!originalConfig.url.startsWith("/auth") && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          await handleUpdateRefreshToken();
          return instance(originalConfig);
        } catch (_error) {
          handleLogout();
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

// TODO: use redux saga to avoid sending multiple time the refresh token request
const handleUpdateRefreshToken = async () => {
  const refreshToken = TokenService.getLocalRefreshToken();
  if (!refreshToken) {
    handleLogout();
    throw new Error("Refresh token is not available. Please login again");
  }
  try {
    const newRefreshToken = await AuthService.refreshToken(refreshToken);
    const { access_token, refresh_token } = newRefreshToken;
    TokenService.updateLocalAccessToken(access_token);
    TokenService.updateLocalRefreshToken(refresh_token);
    store.dispatch(fetchUserInfoAction(access_token));
  } catch (error) {
    handleLogout();
    throw new Error("Refresh token is invalid. Logging out...");
  }
};

export default instance;
